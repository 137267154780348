<template>
  <div class="max-w mx-auto self-start mt-2">
    <h2 class="text-lg font-medium text-gray-900">Choose option</h2>
    <p class="mt-1 text-sm text-gray-500">Get started by selecting what you want to do.</p>
    <Spinner v-if="loading"></Spinner>
    <ul role="list" class="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200" v-if="!loading">
      <li v-for="(item, itemIdx) in items" :key="itemIdx">
        <div :class="['relative group py-4 flex items-start space-x-3 hover:bg-gray-50 select-none', (item.permission ? 'opacity-1': 'opacity-40 cursor-pointer pointer-events-none')]">
          <div class="flex-shrink-0">
            <span :class="[item.iconColor, 'inline-flex items-center justify-center h-10 w-10 rounded-lg']">
              <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
            </span>
          </div>
          <div class="min-w-0 flex-1">
            <div class="text-sm font-medium text-gray-900">
              <router-link :to="item.permission ? item.href : ''" v-if="item.href.length>0">
                <span class="absolute inset-0" aria-hidden="true" />
                {{ item.name }}
              </router-link>
              <div class="cursor-pointer" v-if="item.href.length===0 && item.specialOption === 'csv'" @click.prevent="importCSV">
                <span class="absolute inset-0" aria-hidden="true" />
                {{ item.name }}
              </div>
            </div>
            <p class="text-sm text-gray-500">{{ item.description }}</p>
          </div>
          <div class="flex-shrink-0 self-center">
            <ChevronRightIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
          </div>
        </div>
      </li>
    </ul>

  </div>
</template>

<script>
import Spinner  from '@/components/loadingSpinner.vue';
import { ViewListIcon, PlusIcon, ChevronRightIcon, CalendarIcon, FireIcon } from '@heroicons/vue/outline';

// const items = [
//   {
//     name: 'Complaint list',
//     description: 'View, modify or delete existing complaints.',
//     href: '/dashboard/complaints/list',
//     iconColor: 'bg-yellow-300',
//     icon: ViewListIcon,
//     specialOption: null,
//     permission: false,
//   },
//   {
//     name: 'New complaint',
//     description: 'Add new complaint manually.',
//     href: '/dashboard/complaints/new',
//     iconColor: 'bg-red-400',
//     icon: PlusIcon,
//     specialOption: null,
//     permission: f,
//   },
//   {
//     name: 'Calendar',
//     description: 'Check complaints at specific date and manage team tasks.',
//     href: '/dashboard/complaints/calendar',
//     iconColor: 'bg-blue-400',
//     icon: CalendarIcon,
//     specialOption: null,
//     permission: false,
//   }
// ]

export default {
  components: {
    ChevronRightIcon,
    Spinner
  },
  created()
  {
    this.createMenu()
  },
  data() {
    return {
      loading: false,
      items: [],
      amountOfPending: 0
    }
  },
  methods: {
    async createMenu()
    {
      this.loading = true;
      const result = await this.$store.state.userData.system.ref.collection("ComplaintsBuffer").get().catch((error)=>console.log(error));
      this.amountOfPending = result.docs.length;

      this.items = [
        {
          name: 'Complaints',
          description: 'View, modify or delete existing complaints.',
          href: '/dashboard/complaints/list',
          iconColor: 'bg-yellow-500',
          icon: ViewListIcon,
          specialOption: null,
          permission: true,
        },
        {
          name: `Pending (${this.amountOfPending})`,
          description: 'Review tickets from customer portal.',
          href: '/dashboard/complaints/pending',
          iconColor: 'bg-green-500',
          icon: FireIcon,
          specialOption: null,
          permission: true,
        },
        {
          name: 'New complaint',
          description: 'Add new complaint manually.',
          href: '/dashboard/complaints/new',
          iconColor: 'bg-red-500',
          icon: PlusIcon,
          specialOption: null,
          permission: this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingComplaints && !this.$store.state.userData.systemCreator
        },
        {
          name: 'Calendar',
          description: 'Check complaints at specific date and manage team tasks.',
          href: '/dashboard/complaints/calendar',
          iconColor: 'bg-blue-400',
          icon: CalendarIcon,
          specialOption: null,
          permission: true
        }
      ]
      setTimeout(()=>
      {
        this.loading = false
      },400)   
    }
  }
  }
</script>