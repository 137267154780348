<template>
  <div id="ComplaintList">
    <div class="flex sm:items-baseline sm:space-x-4 mt-2">
        <router-link to="/dashboard/complaints/" class="text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span> Back to complaints menu</router-link>
    </div>
    <label for="search" class="block text-sm font-medium text-gray-700 mt-2">Search</label>
    <div class="flex justify-between items-center">
        <div class="flex items-center justify-center">
            <div class="flex items-center">
                <div class="relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                    <input type="text" v-model="search.phrase" name="search" id="search" class="focus:ring-purple-500 focus:border-purple-500 block w-full pl-10 text-sm border-gray-300 rounded-md" placeholder="Search for complaints.." />
                </div>
                
            </div>
            <div class="ml-2 flex justify-center items-center">
                <button type="button" @click.prevent="searchForComplaints"  :class="{'inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white': true, 'bg-purple-300': search.phrase.length === 0, 'bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500': search.phrase.length > 0}">
                    Search
                </button>
            </div>                
        </div>
        <div class="flex items-center justify-center">
          <router-link to="/dashboard/complaints/calendar" type="button" class="mr-2 ml-2 sm:ml-0 inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            <!-- Heroicon name: solid/plus-sm -->
            <CalendarIcon class="h-5 w-5 text-white"></CalendarIcon>
          </router-link>
            
            <router-link v-if="this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingComplaints" to="/dashboard/complaints/new" type="button"  class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
            </router-link>
        </div>    
    </div>
    <div class="mt-2 mb-3 xl:mb-0" v-if="search.active">
        <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-700">
            Results of searching for: "{{search.lastResult.phrase}}" (Found: {{search.found.length}})
            <button @click.prevent="resetSearch()" type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-purple-400 hover:bg-purple-200 hover:text-purple-500 focus:outline-none focus:bg-purple-500 focus:text-white">
            <span class="sr-only">Remove small option</span>
            <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
            </button>
        </span>           
    </div>
    <div v-if="!search.active">
      <div class="xl:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- <select id="tabs" name="tabs" class="block select-none w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
          <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select> -->
        <Listbox as="div" v-model="currentCardIndx">
        <!-- <ListboxLabel class="block text-sm font-medium text-gray-700"> Month </ListboxLabel> -->
        <div class="mt-2 relative">
          <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
            <span class="flex items-center truncate">{{ this.tabs[currentCardIndx].name }} <span  class="text-xs font-normal ml-1" v-show="this.tabs[currentCardIndx].optionalWord.length>0">({{this.tabs[currentCardIndx].optionalWord}})</span></span>
            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
              <ListboxOption @click.prevent="changeCard(tabNdx)" as="template" v-for="(tab,tabNdx) in tabs" :key="tab.name" :value="tabNdx" v-slot="{ active, selected }">
                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{ tab.name }} <span  class="text-xs font-normal ml-1" v-show="tab.optionalWord.length>0">({{tab.optionalWord}})</span></span>

                  <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      </div>
      <div class="hidden xl:block select-none">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <div v-for="(tab,tabNdx) in tabs" @click.prevent="changeCard(tabNdx)" :key="tab.name" :class="[tab.current ? 'border-purple-500 text-purple-600 cursor-pointer' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer']" :aria-current="tab.current ? 'page' : undefined">
              {{ tab.name }} <span  class="text-xs font-normal" v-show="tab.optionalWord.length>0">({{tab.optionalWord}})</span>
            </div>
          </nav>
        </div>
      </div>
    </div>

    
    <Spinner v-if="queryLoading"></Spinner>
    <div class="mt-4 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0" v-if="!queryLoading">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!loading">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">ID</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Client</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Priority</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Attendant</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Scheduled</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Products</th>
                              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                  <span class="sr-only">Edit</span>
                              </th>
                            </tr>
                        </thead>

                        <tbody class="divide-y divide-gray-200 bg-white" v-if="!search.active">
                            <tr v-for="(complaint) in complaints" :key="complaint.id">
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div class="flex items-center text-xs  font-medium">
                                      {{complaint.id}}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none hover:text-purple-400 cursor-pointer font-medium">
                                  <router-link class="cursor-pointer" :to="`/dashboard/clients/view/${complaint.client.id}`" target="_blank">{{substringText(complaint.client.name)}}</router-link>
                                </td>

                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none font-medium">
                                    <div>
                                      {{complaint.priority}}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none">
                                    <div :class="{'text-blue-500 font-medium': complaint.assignedTo.attendantId === $store.state.user.uid}">
                                      {{substringText(getAttendantById(complaint.assignedTo.attendantId))}}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none">
                                    <div :class="{'font-medium': true,'text-yellow-500': daysRemaining(complaint.scheduledEnd.asText) === 0 && !complaint.completed, 'text-red-500': daysRemaining(complaint.scheduledEnd.asText) < 0 && !complaint.completed }">
                                      {{complaint.scheduledEnd.asText}}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none font-medium">
                                  <div v-if="complaint.cases.length === 0">
                                    No cases
                                  </div>
                                  <div v-if="complaint.cases.length !== 0">
                                    {{complaint.cases.length === 1 && complaint.cases.length !== 0 ? `${complaint.cases[0].item.name}` : `${complaint.cases[0].item.name} + ${complaint.cases.length-1} more`}}
                                  </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none">
                                  <span v-if="!complaint.completed" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"> In progress </span>
                                  <span v-if="complaint.completed" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"> Completed </span>
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                      <router-link :to="`/dashboard/complaints/edit?id=${complaint.id}`" target="_blank" class="text-purple-600 hover:text-purple-900"
                                      >
                                      <div class="relative inline-flex items-center px-4 py-2 rounded-md  border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500">Manage</div>
                                      </router-link>
                                </td>
                            </tr>
                        </tbody>

                        <tbody class="divide-y divide-gray-200 bg-white" v-if="search.active">
                            <tr v-for="(complaint) in search.found" :key="complaint.id">
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                    <div class="flex items-center text-xs  font-medium">
                                      {{complaint.id}}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none hover:text-purple-400 cursor-pointer font-medium">
                                  <router-link class="cursor-pointer" :to="`/dashboard/clients/view/${complaint.client.id}`" target="_blank">{{substringText(complaint.client.name)}}</router-link>
                                </td>

                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none font-medium">
                                    <div>
                                      {{complaint.priority}}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none">
                                    <div :class="{'text-blue-500 font-medium': complaint.assignedTo.attendantId === $store.state.user.uid}">
                                      {{substringText(getAttendantById(complaint.assignedTo.attendantId))}}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none">
                                    <div :class="{'font-medium': true,'text-yellow-500': daysRemaining(complaint.scheduledEnd.asText) === 0 && !complaint.completed, 'text-red-500': daysRemaining(complaint.scheduledEnd.asText) < 0 && !complaint.completed }">
                                      {{complaint.scheduledEnd.asText}}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none font-medium">
                                  <div v-if="complaint.cases.length === 0">
                                    No cases
                                  </div>
                                  <div v-if="complaint.cases.length !== 0">
                                    {{complaint.cases.length === 1 && complaint.cases.length !== 0 ? `${complaint.cases[0].item.name}` : `${complaint.cases[0].item.name} + ${complaint.cases.length-1} more`}}
                                  </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none">
                                  <span v-if="!complaint.completed" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"> In progress </span>
                                  <span v-if="complaint.completed" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"> Completed </span>
                                </td>
                                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                      <router-link :to="`/dashboard/complaints/edit?id=${complaint.id}`" target="_blank" class="text-purple-600 hover:text-purple-900"
                                      >
                                      <div class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500">Edit</div>
                                      </router-link>
                                      <router-link :to="`/dashboard/product/edit`" target="_blank" class="text-purple-600 hover:text-purple-900"
                                      >
                                      <div type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500">View</div>
                                     </router-link> 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false && !queryLoading && !search.active && complaints.length !== 0">
        <button type="button" @click.prevent="loadMore" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Load more..</button>
    </div> 
    <EmptyState class="mt-2 mb-2" v-if="(complaints.length === 0 && !search.active && !queryLoading)  || (search.found.length === 0 && search.active && !queryLoading)"></EmptyState>
    
    
  </div>
</template>

<script>
    import {db} from "@/firebase/gfbconf.js";
    import { SearchIcon, PlusSmIcon, CalendarIcon} from '@heroicons/vue/outline';
    import { CheckIcon, SelectorIcon} from '@heroicons/vue/solid'
    import EmptyState from '../components/EmptyState.vue';
    import Spinner  from '@/components/loadingSpinner.vue';
    import moment from 'moment';
    import axios from 'axios';
    import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
    export default {
        name: "ComplaintList",
        components: {SearchIcon,PlusSmIcon, EmptyState, Spinner, CalendarIcon, Listbox, ListboxButton, ListboxOption, ListboxOptions, CheckIcon, SelectorIcon},
        data: function () {
            return {
                query: null,
                currentCardIndx: 0,
                queryLoading: false,
                currentQueryType: "all",
                complaints: [],
                loading: false,
                queryLimitReached: false,
                queryLimit: 25,
                dbListener: null,
                lastLoaded: null,
                tabs: [
                  { name: 'All', optionalWord: "", current: true, queryType: "all" },{ name: 'Assigned', optionalWord: `to me`, current: false, queryType: "forMe" },{ name: 'Created', optionalWord: `by me`, current: false, queryType: "createdByMe" },{ name: 'Today', optionalWord: "Created", current: false, queryType: "todayCreated" },{ name: 'Today', optionalWord: "Scheduled end", current: false, queryType: "todayEnds" },{ name: 'Delayed', optionalWord: "", current: false, queryType: "delay" }, { name: 'In progress', optionalWord: "", current: false, queryType: "inProgress" }, { name: 'Completed', optionalWord: "", current: false, queryType: "completed" }
                ],
                search: {
                    phrase: "",
                    active: false,
                    found: [],
                    lastResult: null
                }
            }
        },
        async created()
        {
          await this.getAllComplaints();
        },
        methods: {
          async searchForComplaints()
          {
              if(this.search.phrase.length>0)
              {
                  this.queryLoading = true;
                  const result = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/search/complaints", {
                      system: this.$store.state.userData.system.id,
                      phrase: this.search.phrase
                  }).catch((error)=>
                  {
                      console.log(error);
                  });
                  setTimeout(()=>
                  {
                    this.queryLoading = false;
                  },300)
                  
                  if(result.data.status === "ok")
                  {
                      this.search.active = true;
                      this.search.lastResult = result.data;
                      this.search.found = result.data.result;
                  }
                  
              }
          },
          continueListening()
          {
              this.queryLimit = this.queryLimit + 10;
              this.dbListener = this.query.limit(this.queryLimit)
              .onSnapshot((querySnapshot) => {
                  this.complaints = [];
                  if(querySnapshot.docs.length<this.queryLimit)
                  {
                      this.queryLimitReached = true;
                  }
                  else
                  {
                      this.queryLimitReached = false;
                  }
                  querySnapshot.forEach((doc) => {
                    this.complaints.push(doc.data());
                    this.lastLoaded = doc.data().createdDate.unix;
                  });
              });
          },
          loadMore()
          {
            this.dbListener();
            this.continueListening();
          },
          substringText(name)
          {
            if(name.length>18)
            {
              name = name.substring(0,18) + ".."
            }
            return name;
          },
          resetSearch()
          {
              this.search.active = false
              this.search.phrase = "";
          },
          changeCard(tabNdx)
          {
            for(let i=0; i<this.tabs.length;i++)
            {
              this.tabs[i].current = false;
            }
            this.tabs[tabNdx].current = true;
            this.currentCardIndx = tabNdx
            this.dbListener();
            this.currentQueryType = this.tabs[tabNdx].queryType;
            this.getAllComplaints();
          },
          daysRemaining(dateStr) {
              var eventdate = moment(dateStr);
              var todaysdate = moment();
              return eventdate.diff(todaysdate, 'days');
          },
          getAttendantById(id)
          {
            let attendantName = "DELETED";
            for(let i=0; i<this.$store.state.systemData.users.length;i++)
            {
              if(this.$store.state.systemData.users[i].id.toString() === id.toString())
              {
                attendantName = this.$store.state.systemData.users[i].displayName;
              }
            }
            return attendantName
          },
          async getAllComplaints()
          {
            this.resetSearch();
            this.search.active = false;
            this.query = null;
            this.queryLimit = 25;
            if(this.dbListener !== null)
            {
              this.dbListener();
              this.dbListener = null;
            }
            this.queryLoading = true;
            let whatIsToday = moment().format("YYYY-MM-DD").toString()
            let yesterdayUnix = moment().subtract(1,'days').unix();
            switch(this.currentQueryType)
            {
              case 'all':
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Complaints").orderBy("createdDate.unix","desc");
                break;
              case 'inProgress':
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Complaints").where('completed', "==", false).orderBy("createdDate.unix","desc");
                break;
              case 'completed':
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Complaints").where('completed', "==", true).orderBy("createdDate.unix","desc");
                break;
              case 'delay':
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Complaints").where('completed', "==", false).where('scheduledEnd.unix', "<=", yesterdayUnix).orderBy("scheduledEnd.unix","asc");
                break;
              case 'todayCreated':
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Complaints").where('createdDate.asText', "==", whatIsToday).orderBy("createdDate.unix","desc");
                break;
              case 'todayEnds':
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Complaints").where('scheduledEnd.asText', "==", whatIsToday).orderBy("createdDate.unix","desc");
                break;
              case 'createdByMe':
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Complaints").where("meta.creatorId", "==", this.$store.state.user.uid).orderBy("createdDate.unix","desc");
                break;
              case 'forMe':
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Complaints").where("assignedTo.attendantId", "==", this.$store.state.user.uid).orderBy("createdDate.unix","desc");
                break;
              default:
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Complaints").orderBy("createdDate.unix","desc");
                break;
            }

            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.complaints = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                  
                    this.complaints.push(doc.data());
                    this.lastLoaded = doc.data().createdDate.unix;
                });
                setTimeout(()=>
                {
                  this.queryLoading = false;
                },300)
            });
          }
        }
    };
</script>