<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10"  @close="closeModal()">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900">Modify scheduled finish date</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" @click="closeModal">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <div>
                      <!-- OLD VERSION -->
                      <!-- <label  class="block text-sm font-medium text-gray-700">Assigned to</label>
                      <select v-model="selectedAttendant" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                        <option v-for="person in attendants" :value="person.id" :key="person.id">{{person.displayName}}</option>
                      </select> -->
                      <Listbox as="div" v-model="selectedAttendant">
                        <ListboxLabel class="block text-sm font-medium text-gray-700"> Assigned to </ListboxLabel>
                        <div class="mt-1 relative">
                          <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                            <span class="flex items-center truncate">{{translateAttendant(selectedAttendant)}}</span>
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                              <ListboxOption as="template" v-for="person in attendants" :value="person.id" :key="person.id" v-slot="{ active, selected }">
                                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{person.displayName}}</span>

                                  <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>
                    </div>
                    <div class="mt-4">
                      <label  class="block text-sm font-medium text-gray-700">Scheduled finalization date</label>
                      <input type="date" v-model="selectedEndDate.asText" @change="updateDateValue" :min="minEndDate" :max="maxEndDate" :class="{'border-gray-300': !errors.endDate, 'border-red-600': errors.endDate, 'focus:outline-none text-sm focus:ring-purple-500 focus:border-purple-500 rounded-md w-full': true}" >  
                      <span class="mt-2 text-xs text-red-400" v-if="errors.endDate">Date should be beetwen {{minEndDate}} and {{maxEndDate}}.</span>
                    </div>
                    <div class="mt-4">
                      <!-- OLD VERSION -->
                      <!-- <label class="block text-sm font-medium text-gray-700">Priority</label>
                      <select v-model="selectedPriority" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                        <option v-for="priority in priorityList" :value="priority.name" :key="priority.name">{{priority.name}}</option>
                      </select> -->
                      <Listbox as="div" v-model="selectedPriority">
                        <ListboxLabel class="block text-sm font-medium text-gray-700"> Priority </ListboxLabel>
                        <div class="mt-1 relative">
                          <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                            <span class="flex items-center truncate">{{selectedPriority}}</span>
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                              <ListboxOption as="template" v-for="priority in priorityList" :value="priority.name" :key="priority.name" v-slot="{ active, selected }">
                                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{priority.name}}</span>

                                  <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>
                    </div>
                    <div class="mt-4">
                        <button type="button" @click.prevent="update()" class="inline-flex items-center rounded-md border border-transparent bg-purple-100 px-3 py-2 text-sm font-medium leading-4 text-purple-700 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Update</button>
                    </div>  
              
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import { Listbox, ListboxButton, ListboxOption, ListboxOptions,ListboxLabel } from '@headlessui/vue'
    import { CheckIcon, SelectorIcon} from '@heroicons/vue/solid'
    import { XIcon as XMarkIcon} from '@heroicons/vue/outline';
    import moment from 'moment';
    //import axios from 'axios';
    import {db} from "@/firebase/gfbconf.js"
    export default {
        name: "External Ids Modal",
        data()
        {
            return {
                minEndDate: null,
                maxEndDate: null,
                selectedEndDate: null,
                open: true,
                data: null,
                attendants: [],
                selectedAttendant: "",
                priorityList: [],
                selectedPriority: null,
                previousSelectedPriority: null,
                errors: {
                  endDate: false
                }
            }
        },
        props: ['dataToModify','dbInfo'],
        components: {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,XMarkIcon,Listbox, ListboxButton, ListboxOption, ListboxOptions,ListboxLabel,CheckIcon, SelectorIcon},
        async created()
        {
          this.setMaxAndMinEndDate()
          this.getAttendants();
          this.setCurrentEndDate()
          await this.getPriority();
        },
        methods:
        {
            translateAttendant(attendantId){
              for(let i=0;i < this.attendants.length;i++)
              {
                if(this.attendants[i].id === attendantId)
                {
                  return this.attendants[i].displayName
                }
              }
            },
            async getPriority()
            {
              // Get priority
              const result = await db.collection("OptionValues").doc("ComplaintPriority").get();
              if(result.exists)
              {
                this.priorityList = result.data().values;
                this.selectedPriority = this.dataToModify.priority; 
                this.previousSelectedPriority = this.dataToModify.priority; 
              }
            },
            updateDateValue()
            {
              this.selectedEndDate = {                
                asText: this.selectedEndDate.asText,
                unix: moment(this.selectedEndDate.asText,"YYYY-MM-DD").unix(),
                dateObj: moment(this.selectedEndDate.asText,"YYYY-MM-DD").toDate(),
                dayOfWeek: moment(this.selectedEndDate.asText,"YYYY-MM-DD").day(),
                dayNum: moment(this.selectedEndDate.asText,"YYYY-MM-DD").date(),
                monthNum: moment(this.selectedEndDate.asText,"YYYY-MM-DD").month() + 1,
                year: moment(this.selectedEndDate.asText,"YYYY-MM-DD").year(),
                weekNum: moment(this.selectedEndDate.asText,"YYYY-MM-DD").week()
              };
            },
            setCurrentEndDate()
            {
              this.selectedEndDate = {...this.dataToModify.scheduledEnd};
            },
            async getAttendants()
            {
              // Get attendants
              const result = await this.$store.state.userData.system.ref.get();
              if(result.exists)
              {
                this.attendants = result.data().users;
                this.selectedAttendant = this.dataToModify.assignedTo.attendantId;
              }
            },
            setMaxAndMinEndDate()
            {
              this.minEndDate = moment().format("YYYY-MM-DD").toString();
              this.maxEndDate = moment().add("10", "years").format("YYYY-MM-DD").toString();
            },
            closeModal()
            {
                this.open = false;
                setTimeout(() => {
                    this.open = true;
                    this.$emit("close");
                },300)
            },
            async update()
            {
              try{
                this.errors.endDate = false;
                const dateHasChanged = this.selectedEndDate.asText !== this.dataToModify.scheduledEnd.asText ? true : false;
                if(!dateHasChanged)
                {
                  await this.$store.state.userData.system.ref.collection("Complaints").doc(this.dbInfo.docId.toString()).update({
                      "priority": this.selectedPriority,
                      "assignedTo": {
                        attendantId: this.selectedAttendant,
                        ref: db.collection("UsersData").doc(this.selectedAttendant)
                      }
                  })
                  let checkExst = await this.$store.state.userData.system.ref.collection("Complaints").doc(this.dbInfo.docId.toString()).get()
                  if(checkExst.exists){
                    await this.$store.state.userData.system.ref.collection("Chats").doc(this.dbInfo.docId.toString()).update({
                        "attendantId": this.selectedAttendant
                    })
                  }
                }
                else
                {
                  const selected = moment(this.selectedEndDate.asText,"YYYY-MM-DD")
                  const start = moment(this.minEndDate, "YYYY-MM-DD" );
                  const end = moment(this.maxEndDate,"YYYY-MM-DD");
                  const isBetween = selected.isBetween(start,end, undefined, '[]');

                  if(isBetween)
                  {
                    await this.$store.state.userData.system.ref.collection("Complaints").doc(this.dbInfo.docId.toString()).update({
                        "priority": this.selectedPriority,
                        "scheduledEnd": this.selectedEndDate,
                        "assignedTo": {
                          attendantId: this.selectedAttendant,
                          ref: db.collection("UsersData").doc(this.selectedAttendant)
                        }
                    });
                    let checkExst = await this.$store.state.userData.system.ref.collection("Complaints").doc(this.dbInfo.docId.toString()).get()
                    if(checkExst.exists){
                      await this.$store.state.userData.system.ref.collection("Chats").doc(this.dbInfo.docId.toString()).update({
                          "attendantId": this.selectedAttendant
                      })
                    }
                  }
                  else
                  {
                    this.errors.endDate = true;
                    return false;
                  }
                }
                if(this.errors.endDate === false)
                {
                  this.updateAnalytics()
                  this.$emit("reloadComplaint");
                  this.$store.commit('setNotification',{
                      show: true,
                      head: "Update has been completed!",
                      subheader: "",
                      success: true
                  });
                  this.closeModal(); 
                }
              }
              catch(error)
              {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Oops.. Something went wrong!",
                    subheader: "",
                    success: false
                });
                this.closeModal(); 
              }

            },
            async updateAnalytics(){
               if(this.selectedPriority !== this.previousSelectedPriority) {
                const refToYear = this.$store.state.userData.system.ref.collection('Analytics').doc(this.dbInfo.createdDate.year.toString())

                // prepare objects
                let yearData = (await refToYear.get()).data()
                let monthData = yearData[moment(this.dbInfo.createdDate.monthNum, 'MM').format('MMMM')]
                let dayData = monthData.days[this.dbInfo.createdDate.dayNum]

                // change priority in year
                yearData.global.complaints.priorityStats[this.previousSelectedPriority.replace(/\s+/g, '')]--
                yearData.global.complaints.priorityStats[this.selectedPriority.replace(/\s+/g, '')]++

                // change priority in month
                monthData.global.complaints.priorityStats[this.previousSelectedPriority.replace(/\s+/g, '')]--
                monthData.global.complaints.priorityStats[this.selectedPriority.replace(/\s+/g, '')]++

                // change priority in day
                dayData.global.complaints.priorityStats[this.previousSelectedPriority.replace(/\s+/g, '')]--
                dayData.global.complaints.priorityStats[this.selectedPriority.replace(/\s+/g, '')]++

                await refToYear.update(yearData)
                this.previousSelectedPriority = this.selectedPriority
              }
            }
        },
        computed:
        {
        }
    }
</script>
<style lang="scss" scoped>

</style>