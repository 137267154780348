<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <div class="pointer-events-auto relative w-screen max-w-md">
                <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                  <div class="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                    <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="open = false">
                      <span class="sr-only">Close panel</span>
                      <XIcon class="h-6 w-6" @click.prevent="closeModal" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div class="px-4 sm:px-6">
                    <DialogTitle class="text-lg font-medium text-gray-900 select-none"> Attributes <span class="text-xs opacity-60">(at complaint creation time)</span> </DialogTitle>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <div class="bg-white shadow overflow-hidden sm:rounded-md" v-if="item.attributesAtAddingTime.length !== 0">
                        <ul role="list" class="divide-y divide-gray-100">
                        <li v-for="attribVal in item.attributesAtAddingTime" :key="attribVal.id">
                            <div class="block ">
                            <div class="px-4 py-4 sm:px-6">
                                <div class="flex items-center justify-between">
                                <p class="text-xs font-medium text-purple-600 truncate">
                                    {{attribVal.parent.name}}
                                </p>
                                </div>
                                <div class="mt-2 sm:flex sm:justify-between">
                                <div class="sm:flex">
                                    <p class="flex items-center text-sm text-gray-500">
                                        {{attribVal.name}}
                                    </p>
                                </div>

                                </div>
                            </div>
                            </div>
                        </li>
                        </ul>
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import { Dialog,  DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { XIcon } from '@heroicons/vue/outline'
    export default {
        name: "AttribsValuesAtAddingTime",
        props: ['item'],
        components: {Dialog, DialogTitle, TransitionChild, TransitionRoot, XIcon},
        data()
        {
            return {
                open: true,
                loading: false,
            }
        },
        methods:
        {
            closeModal()
            {
                this.$emit("close");
            }
        }
    }
</script>
