<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10"  @close="closeModal()">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900">Modify external identificators</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" @click="closeModal">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Client ID</label>
                        <div class="mt-1">
                            <input type="text" v-model="data.clientNum" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" placeholder="Client ID from external source" />
                        </div>
                    </div>
                    <div class="mt-4">
                        <label class="block text-sm font-medium text-gray-700">Invoice ID</label>
                        <div class="mt-1">
                            <input type="text" v-model="data.invoiceNum" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" placeholder="Invoice ID from external source" />
                        </div>
                    </div>
                    <div class="mt-4">
                        <label class="block text-sm font-medium text-gray-700">Order ID</label>
                        <div class="mt-1">
                            <input type="text" v-model="data.orderNum" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" placeholder="Order ID from external source" />
                        </div>
                    </div>
                    <div class="mt-4">
                        <button type="button" @click.prevent="update()" class="inline-flex items-center rounded-md border border-transparent bg-purple-100 px-3 py-2 text-sm font-medium leading-4 text-purple-700 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Update</button>
                    </div>                   
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import { XIcon as XMarkIcon} from '@heroicons/vue/outline';
    // import axios from 'axios';
    //import {db,storage} from "@/firebase/gfbconf.js"
    export default {
        name: "External Ids Modal",
        data()
        {
            return {
                open: true,
                data: null
            }
        },
        props: ['dataToModify','dbInfo'],
        components: {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,XMarkIcon},
        created()
        {
            this.prepareDataForInputs()
        },
        methods:
        {
            closeModal()
            {
                this.open = false;
                setTimeout(() => {
                    this.open = true;
                    this.$emit("close");
                },300)
            },
            prepareDataForInputs()
            {
                this.data = {... this.dataToModify};
            },
            async update()
            {
                await this.$store.state.userData.system.ref.collection("Complaints").doc(this.dbInfo.docId.toString()).update({
                    [`${this.dbInfo.key}`]: this.data
                })
                this.$emit("reindexElasticSearch");
                this.$emit("reloadComplaint");
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Update has been completed!",
                    subheader: "",
                    success: true
                });
                this.closeModal();
            }
        },
        computed:
        {
        }
    }
</script>
<style lang="scss" scoped>

</style>