<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10"  @close="() => null">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900">Modify delivery</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" @click="closeModal">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <div>
                        <!-- OLD VERSION -->
                        <!-- <label class="block text-sm font-medium text-gray-700">Type</label>
                        <select v-model="data.type" @change="checkIfDeliveryClearNeeded" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                          <option value="Not applicable">Not applicable</option>
                          <option value="Delivery and pickup">Delivery and pickup</option>
                          <option value="Delivery">Delivery</option>
                          <option value="Pickup">Pickup</option>
                        </select> -->
                        <Listbox as="div" v-model="data.type">
                          <ListboxLabel class="block text-sm font-medium text-gray-700"> Type </ListboxLabel>
                          <div class="mt-1 relative">
                            <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                              <span class="flex items-center truncate">{{data.type}}</span>
                              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </ListboxButton>

                            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                              <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                <ListboxOption @click.prevent="checkIfDeliveryClearNeeded" as="template" value="Not applicable" v-slot="{ active, selected }">
                                  <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Not applicable</span>

                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  </li>
                                </ListboxOption>
                                <ListboxOption @click.prevent="checkIfDeliveryClearNeeded" as="template" value="Delivery and pickup" v-slot="{ active, selected }">
                                  <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Delivery and pickup</span>

                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  </li>
                                </ListboxOption>
                                <ListboxOption @click.prevent="checkIfDeliveryClearNeeded" as="template" value="Delivery" v-slot="{ active, selected }">
                                  <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Delivery</span>

                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  </li>
                                </ListboxOption>
                                <ListboxOption @click.prevent="checkIfDeliveryClearNeeded" as="template" value="Pickup" v-slot="{ active, selected }">
                                  <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Pickup</span>

                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  </li>
                                </ListboxOption>
                              </ListboxOptions>
                            </transition>
                          </div>
                        </Listbox>
                    </div>
                    <div class="relative mt-4" v-if="data.type !== 'Not applicable'">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300" />
                        </div>
                        <div class="relative flex justify-start">
                        <span class="bg-white pr-2 text-sm text-gray-500">Delivery</span>
                        </div>
                    </div>
                    <div class="mt-4" v-if="data.type !== 'Not applicable'">
                        <!-- OLD VERSION -->
                        <!-- <label class="block text-sm font-medium text-gray-700">Completed</label>
                        <select v-model="data.done" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select> -->
                        <Listbox as="div" v-model="data.done">
                          <ListboxLabel class="block text-sm font-medium text-gray-700"> Completed </ListboxLabel>
                          <div class="mt-1 relative">
                            <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                              <span class="flex items-center truncate">{{data.done ? "Yes" : "No"}}</span>
                              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </ListboxButton>

                            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                              <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                <ListboxOption as="template" :value="true" v-slot="{ active, selected }">
                                  <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Yes</span>

                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  </li>
                                </ListboxOption>
                                <ListboxOption as="template" :value="false" v-slot="{ active, selected }">
                                  <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">No</span>

                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  </li>
                                </ListboxOption>
                              </ListboxOptions>
                            </transition>
                          </div>
                        </Listbox>
                    </div>
                    <div class="mt-4" v-if="data.type !== 'Not applicable'">
                        <label class="block text-sm font-medium text-gray-700">Courier</label>
                        <div class="mt-1">
                            <input type="text" v-model="data.courier" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" placeholder="Courier" />
                        </div>
                    </div>
                    <div class="mt-4" v-if="data.type !== 'Not applicable'">
                        <label class="block text-sm font-medium text-gray-700">Name</label>
                        <div class="mt-1">
                            <input type="text" v-model="data.name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" placeholder="Name" />
                        </div>
                    </div>
                    <div class="mt-4" v-if="data.type !== 'Not applicable'">
                        <label class="block text-sm font-medium text-gray-700">Address</label>
                        <div class="mt-1">
                            <input type="text" v-model="data.address" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" placeholder="Address" />
                        </div>
                    </div>
                    <div class="mt-4" v-if="data.type !== 'Not applicable'">
                        <label class="block text-sm font-medium text-gray-700">City</label>
                        <div class="mt-1">
                            <input type="text" v-model="data.city" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" placeholder="City" />
                        </div>
                    </div>
                    <div class="mt-4" v-if="data.type !== 'Not applicable'">
                        <label class="block text-sm font-medium text-gray-700">Post code</label>
                        <div class="mt-1">
                            <input type="text" v-model="data.postCode" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" placeholder="Post code" />
                        </div>
                    </div>
                    <div class="mt-4" v-if="data.type !== 'Not applicable'">
                        <label class="block text-sm font-medium text-gray-700">Country</label>
                        <div class="mt-1">
                            <input type="text" v-model="data.country" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" placeholder="Country" />
                        </div>
                    </div>
                    <div class="mt-4" v-if="data.type !== 'Not applicable'">
                        <label class="block text-sm font-medium text-gray-700">Phone Number</label>
                        <div class="mt-1">
                            <input type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45' v-model="data.phoneNum" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" placeholder="Phone number" />
                        </div>
                    </div>
                    <div class="mt-4">
                        <button type="button" @click.prevent="update()" class="inline-flex items-center rounded-md border border-transparent bg-purple-100 px-3 py-2 text-sm font-medium leading-4 text-purple-700 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Update</button>
                    </div>                   
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import { XIcon as XMarkIcon} from '@heroicons/vue/outline';
    import { Listbox, ListboxButton, ListboxOption, ListboxOptions,ListboxLabel } from '@headlessui/vue'
    import { CheckIcon, SelectorIcon} from '@heroicons/vue/solid'
    import moment from 'moment'

    // import axios from 'axios';
    import {db} from "@/firebase/gfbconf.js"
    export default {
        name: "Delivery Modal",
        data()
        {
            return {
                open: true,
                data: null
            }
        },
        props: ['dataToModify','dbInfo'],
        components: {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,XMarkIcon,Listbox, ListboxButton, ListboxOption, ListboxOptions,ListboxLabel,CheckIcon, SelectorIcon},
        created()
        {
            this.prepareDataForInputs()
        },
        methods:
        {
            checkIfDeliveryClearNeeded()
            {
              if(this.data.type === 'Not applicable')
              {
                this.data = {
                  done: false,
                  type: "Not applicable",
                  courier: "",
                  name: "",
                  phoneNum: "",
                  address: "",
                  city: "",
                  postCode: "",
                  country: "",
                }
              }
            },
            closeModal()
            {
                this.open = false;
                setTimeout(() => {
                    this.open = true;
                    this.$emit("closeModal");
                },300)
            },
            prepareDataForInputs()
            {
                this.data = {... this.dataToModify};
                this.dataBackup = JSON.parse(JSON.stringify(this.data))
            },
            async update()
            {

                if(JSON.stringify(this.data) !== JSON.stringify(this.dataBackup)) {
                  
                const refToYear = this.$store.state.userData.system.ref.collection('Analytics').doc(this.dbInfo.createdDate.year.toString())

                // prepare analitycs objects
                let yearData = (await refToYear.get()).data()
                let monthData = yearData[moment(this.dbInfo.createdDate.monthNum, 'MM').format('MMMM')]
                let dayData = monthData.days[this.dbInfo.createdDate.dayNum]


                let deliveryTypeBackup = this.dataBackup.type === "Delivery" ? 'delivery' : this.dataBackup.type === "Pickup" ? 'pickup' : this.dataBackup.type === "Delivery and pickup" ? 'deliveryAndPickup' : ''
                let deliveryType = this.data.type === "Delivery" ? 'delivery' : this.data.type === "Pickup" ? 'pickup' : this.data.type === "Delivery and pickup" ? 'deliveryAndPickup' : ''
                if(this.data.type === 'Not applicable') {
                  
                  dayData.global.complaints.deliveryData[deliveryTypeBackup] -= 1
                  monthData.global.complaints.deliveryData[deliveryTypeBackup] -= 1
                  yearData.global.complaints.deliveryData[deliveryTypeBackup] -= 1
                } else if(this.data.type !== this.dataBackup.type) {
                  if(deliveryTypeBackup.length > 0) {
                    dayData.global.complaints.deliveryData[deliveryTypeBackup] -= 1
                    monthData.global.complaints.deliveryData[deliveryTypeBackup] -= 1
                    yearData.global.complaints.deliveryData[deliveryTypeBackup] -= 1                    
                  }
                    dayData.global.complaints.deliveryData[deliveryType] += 1
                    monthData.global.complaints.deliveryData[deliveryType] += 1
                    yearData.global.complaints.deliveryData[deliveryType] += 1 
                }

                await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc(this.dbInfo.createdDate.year.toString()).set(yearData);
                }

                await this.$store.state.userData.system.ref.collection("Complaints").doc(this.dbInfo.docId.toString()).update({
                    [`${this.dbInfo.key}`]: this.data
                })
                // this.$emit("reindexElasticSearch");
                this.$emit("reloadComplaint");
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Update has been completed!",
                    subheader: "",
                    success: true
                });
                this.closeModal();
            }
        },
        computed:
        {
        }
    }
</script>
<style lang="scss" scoped>

</style>