<template>
    <div v-if="complaint !== null" class="flex items-center justify-between">
        <div class="flex items-center">
            <span class="mr-1 font-medium">{{complaint.id}}</span>
        </div>
        <div class="text-xs">
            <span><span class="font-medium">Last modification:</span> {{getModificationDateString(complaint.meta.lastUpdate)}}</span>
        </div>
    </div>
    <div class="flex sm:items-baseline sm:space-x-4 mt-2 mb-4">
        <router-link to="/dashboard/complaints/list" class="text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span> Back to complaint list</router-link>
    </div>
    <div>
        <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <Listbox as="div" v-model="currentContainerNum">
            <div class="mt-1 relative">
            <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                <span class="flex items-center truncate">{{ this.tabs[currentContainerNum-1].name }}</span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                <ListboxOption @click.prevent="setTab($event,tab)" as="template" v-for="(tab,tabNdx) in tabs" :key="tab.name" :value="tabNdx+1" v-slot="{ active, selected }">
                    <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{ tab.name }}</span>

                    <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                    </li>
                </ListboxOption>
                </ListboxOptions>
            </transition>
            </div>
        </Listbox>
        </div>
        <div class="hidden sm:flex sm:justify-between">
        <nav class="flex space-x-0 lg:space-x-1 xl:space-x-4" aria-label="Tabs">
            <div @click.prevent="setTab($event,tab)" v-for="tab in tabs" :key="tab.name" :class="[tab.current ? 'bg-blue-100 text-blue-700' : 'text-gray-500 hover:text-gray-700', 'px-3 py-2 font-medium text-sm md:text-xs xl:text-sm rounded-md cursor-pointer select-none transition ease-in duration-300']" :aria-current="tab.current ? 'page' : undefined">
            {{ tab.name }}
            </div>
        </nav>
        <div class="hidden lg:flex space-x-2">
            <button @click.prevent="openChat()" v-if="complaint !== null" type="button" :class="{'transition ease-in duration-300 inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm md:text-xs xl:text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-white hover:bg-purple-600 bg-purple-500 focus:ring-purple-500': true}"><ChatAlt2Icon class="h-4 w-4 mr-1.5" aria-hidden="true" />Chat</button>
            <button @click.prevent="notifyModal.open = true" v-if="complaint !== null && complaint.client.email.length > 0" type="button" :class="{'transition ease-in duration-300 inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm md:text-xs xl:text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-white hover:bg-purple-600 bg-purple-500 focus:ring-purple-500': true}"><BellIcon class="h-4 w-4 mr-1.5" aria-hidden="true" />Notifications</button>
            <a :href="`http://client.complaia.com/?portal=${this.$store.state.systemData.portal.key}&complaint=${complaint.portalData.formId}`" target="_blank" v-if="complaint !== null" type="button" :class="{'transition ease-in duration-300 inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm md:text-xs xl:text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-white hover:bg-purple-600 bg-purple-500 focus:ring-purple-500': true}"><ExternalLinkIcon class="h-4 w-4 mr-1.5" aria-hidden="true" />Complaint site</a>
        </div>
        </div>
        <div class="w-full text-sm flex lg:hidden space-x-2 mt-4">
            <button @click.prevent="openChat()" v-if="complaint !== null" type="button" :class="{'transition ease-in duration-300 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs sm:text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-white hover:bg-purple-600 bg-purple-500 focus:ring-purple-500': true}"><ChatAlt2Icon class="h-4 w-4 mr-1.5" aria-hidden="true" />Chat</button>
            <button @click.prevent="notifyModal.open = true" v-if="complaint !== null && complaint.client.email.length > 0" type="button" :class="{'transition ease-in duration-300 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs sm:text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-white hover:bg-purple-600 bg-purple-500 focus:ring-purple-500': true}"><BellIcon class="h-4 w-4 mr-1.5" aria-hidden="true" />Notifications</button>
            <a :href="`http://client.complaia.com/?portal=${this.$store.state.systemData.portal.key}&complaint=${complaint.portalData.formId}`" target="_blank" v-if="complaint !== null" type="button" :class="{'transition ease-in duration-300 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs sm:text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-white hover:bg-purple-600 bg-purple-500 focus:ring-purple-500': true}"><ExternalLinkIcon class="h-4 w-4 mr-1.5" aria-hidden="true" />Complaint site</a>
        </div>
    </div>
    <div v-if="complaint !== null">
        <div v-if="currentContainerNum === 1">
            <!-- COMPlETION INFO -->
            <div class="rounded-md bg-green-50 p-4 mt-4 mb-4" v-if="complaint.completed">
                <div class="flex">
                <div class="flex-shrink-0">
                    <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <p class="text-sm font-medium text-green-800">The complaint is completed.</p>
                    <p class="mt-2 text-sm text-green-700">You can open this ticket again by marking any of complaint cases as uncompleted.</p>
                </div>
                </div>
            </div>
            <!-- DELAY INFO -->
            <div class="rounded-md bg-yellow-50 p-4 mt-4 mb-4" v-if="daysRemaining(complaint.scheduledEnd.asText) < 0 && !complaint.completed">
                <div class="flex">
                <div class="flex-shrink-0">
                    <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <h3 class="text-sm font-medium text-yellow-800">The complaint is delayed.</h3>
                    <div class="mt-2 text-sm text-yellow-700">
                        <p>This ticket should be completed by {{complaint.scheduledEnd.asText}}. Close all cases to mark this complaint as completed.</p>
                    </div>
                </div>
                </div>
            </div>

            <!-- FORM -->
            <div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                <div class="px-4 py-5 sm:px-6 flex items-center justify-between">
                    <h3 class="sm:text-lg leading-6 font-medium text-gray-900 select-none">Client</h3>
                    <div>
                        <button @click.prevent="editModals.askForClientReload = true" v-if="!complaint.completed && this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingComplaints" class="mr-2 inline-flex items-center px-2 py-1 sm:px-3 sm:py-2 border border-gray-300 shadow-sm text-xs sm:text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Update from profile
                            <RefreshIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                        </button>
                        <router-link :to="`/dashboard/clients/view/${complaint.client.id}`" target="_blank" class="inline-flex items-center px-2 py-1 sm:px-3 sm:py-2 border border-gray-300 shadow-sm text-xs sm:text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Profile
                            <UserIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                        </router-link>  
                    </div>

                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Full name</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{complaint.client.name.length > 0 ? complaint.client.name : "----"}}</dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Email</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                <span>{{complaint.client.email.length>0 ? complaint.client.email : "----"}}</span>
                                <a :href="`mailto:${complaint.client.email}`" v-if="complaint.client.email.length>0" class="ml-2 text-xs text-purple-500 hover:text-purple-400">
                                    Send
                                </a>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Phone</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                <span>{{complaint.client.phoneNum.length > 0 ? complaint.client.phoneNum : "----"}}</span>
                                <a :href="`tel:${complaint.client.phoneNum}`" v-if="complaint.client.phoneNum.length>0" class="ml-2 text-xs text-purple-500 hover:text-purple-400">
                                    Call
                                </a>
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Address</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span class="block">{{fullAddressString()}}</span>
                            </dd>
                        </div>

                    </dl>
                </div>
            </div> 

            <div class="bg-white shadow overflow-hidden sm:rounded-lg mt-10">
                <div class="px-4 py-5 sm:px-6 flex items-center justify-between">
                    <h3 class="sm:text-lg leading-6 font-medium text-gray-900 select-none">Finalization</h3>
                    <button v-if="!complaint.completed && this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingComplaints" type="button" @click.prevent="editModals.plannedFinalization = true" class="inline-flex items-center px-2 py-1 sm:px-3 sm:py-2 text-xs sm:text-sm border border-gray-300 shadow-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                        Edit
                        <PencilIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                    </button>  
                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Status</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span v-if="!complaint.completed" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"> In progress </span>
                                <span v-if="complaint.completed" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"> Completed </span>
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Scheduled date</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{complaint.scheduledEnd.asText}} <span class="text-xs text-red-500" v-if="daysRemaining(complaint.scheduledEnd.asText) < 0 && !complaint.completed">(Late)</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Assigned to</dt>
                            <dd class="mt-1 flex items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span>{{getAttendantById(complaint.assignedTo.attendantId)}}</span>
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Priority</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800" v-if="complaint.priority === 'Level 1'"> {{complaint.priority}} </span>
                                <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800" v-if="complaint.priority === 'Level 2'"> {{complaint.priority}} </span>
                                <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800" v-if="complaint.priority === 'Level 3'"> {{complaint.priority}} </span>
                                <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800" v-if="complaint.priority === 'Level 4'"> {{complaint.priority}} </span>
                                <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800" v-if="complaint.priority === 'Level 5'"> {{complaint.priority}} </span>
                            </dd>
                        </div>

                    </dl>
                </div>
            </div>

            <div class="bg-white shadow overflow-hidden sm:rounded-lg mt-10">
                <div class="px-4 py-5 sm:px-6 flex items-center  justify-between">
                    <h3 class="sm:text-lg leading-6 font-medium text-gray-900 select-none">External identificators</h3>
                    <button v-if="!complaint.completed && this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingComplaints" type="button" @click.prevent="editModals.externalModifiers = true" class="inline-flex items-center px-2 py-1 sm:px-3 sm:py-2 text-xs sm:text-sm border border-gray-300 shadow-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        Edit
                        <PencilIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                    </button>  
                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Client ID</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{complaint.externalIds.clientNum.length > 0 ? complaint.externalIds.clientNum : "----"}}</dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Invoice ID</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                <span>{{complaint.externalIds.invoiceNum.length>0 ? complaint.externalIds.invoiceNum : "----"}}</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Order ID</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                <span>{{complaint.externalIds.orderNum.length>0 ? complaint.externalIds.orderNum: "----"}}</span>
                            </dd>
                        </div>

                    </dl>
                </div>
            </div>

            <div class="bg-white shadow overflow-hidden sm:rounded-lg mt-10" v-if="this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingComplaints">
                <div class="px-4 py-5 sm:px-6 flex justify-between items-center">
                    <h3 class="flex items-center text-lg leading-6 font-medium text-red-500 select-none"><ExclamationCircleIcon class="w-5 h-5 text-red-500 pr-1"></ExclamationCircleIcon> <span>Danger zone</span></h3>
                    <button type="button" @click.prevent="deleteAsk = true" class="inline-flex items-center rounded border border-transparent bg-red-100 px-2.5 py-1.5 text-xs font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Delete complaint</button>
                </div>
            </div>
        </div>
        <div v-if="currentContainerNum === 2">
            <!-- NO CASE INFO -->
            <div class="rounded-md bg-yellow-50 p-4 mt-4 mb-4" v-if="complaint.cases.length === 0">
                <div class="flex">
                <div class="flex-shrink-0">
                    <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <h3 class="text-sm font-medium text-yellow-800">Action required.</h3>
                    <div class="mt-2 text-sm text-yellow-700">
                        <p>You should have at least one case in complaint. This can lead to problems with analytics.</p>
                    </div>
                </div>
                </div>
            </div>
            <EmptyState v-if="complaint.cases.length === 0" class="mt-4 mb-4"></EmptyState>
            <div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4" v-for="(c,cIndx) in complaint.cases" :key="c.id">
                <div class="px-4 py-5 sm:px-6 flex flex-col lg:flex-row lg:items-center  lg:justify-between">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 select-none flex items-center">
                        <span class="text-xs">Case {{cIndx+1}} <span v-if="casesEditMode[cIndx]" class="opacity-70 text-yellow-600">(EDITING)</span></span>
                        <router-link :to="`/dashboard/product/view?id=${c.item.id}`" target="_blank" class="hover:text-purple-500 flex items-center">
                            <span class="ml-4 text-sm">{{c.item.name}}</span>
                        </router-link>
                        <div v-if="c.item.attributesAtAddingTime.length !== 0">
                            <div class="text-xs text-purple-500 underline hover:text-purple-700 cursor-pointer ml-4" @click.prevent="openAttributeModal(c.item)">Specification</div>
                        </div>
                    </h3>
                    <div class="flex items-center mt-2 lg:m-0">
                        <span :class="{'text-xs uppercase': true, 'text-green-500': c.acceptance === 'accepted','text-red-500': c.acceptance === 'declined'}" v-if="!casesEditMode[cIndx]">{{c.acceptance}}</span>
                        <!-- OLD VERSION -->
                        <!-- <select v-if="casesEditMode[cIndx]" v-model="c.acceptance" @change="correctDataWhenAcceptanceChanged(c)" class=" block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                            <option value="accepted">Accepted</option>
                            <option value="declined">Declined</option>
                        </select> -->
                        <Listbox v-if="casesEditMode[cIndx]" as="div" v-model="c.acceptance">
                            <div class="relative">
                                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                    <span class="flex items-center truncate">{{`${c.acceptance[0].toUpperCase()}${c.acceptance.slice(1)}`}}</span>
                                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </ListboxButton>

                                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                    <ListboxOption @click.prevent="correctDataWhenAcceptanceChanged(c,cIndx)" as="template" value="accepted" v-slot="{ active, selected }">
                                        <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Accepted</span>

                                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                        </li>
                                    </ListboxOption>
                                    <ListboxOption @click.prevent="correctDataWhenAcceptanceChanged(c, cIndx)" as="template" value="declined" v-slot="{ active, selected }">
                                        <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Declined</span>

                                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                        </li>
                                    </ListboxOption>
                                    </ListboxOptions>
                                </transition>
                            </div>
                        </Listbox>
                        <Menu as="div" class="relative inline-block text-left ml-auto sm:ml-4" v-if="this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingComplaints">
                            <div>
                            <MenuButton class="inline-flex w-full justify-center items-center rounded-md border border-gray-300 bg-white px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                Options
                                <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                            </MenuButton>
                            </div>

                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div class="py-1">
                                <MenuItem v-slot="{ active }">
                                    <div v-if="caseInEdit === null || caseInEdit === c">
                                        <button @click.prevent="allowEditMode(cIndx)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'w-full group flex items-center px-4 py-2 text-sm']">
                                        <PencilIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                            <span v-if="!casesEditMode[cIndx]">Edit</span>
                                            <span v-if="casesEditMode[cIndx] && c === caseInEdit">Discard changes</span>
                                        </button>
                                    </div>
                                    <div v-else class="opacity-50">
                                        <button :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-900', 'w-full group flex items-center px-4 py-2 text-sm cursor-not-allowed']">
                                        <PencilIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                            <span>Edit <span class="text-xs">(Other case in edit)</span></span>
                                        </button>
                                    </div>
                                </MenuItem>
                                </div>
                                <div class="py-1">
                                <MenuItem v-slot="{ active }">
                                    <button @click="askForCasesDelete(cIndx)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm w-full']">
                                    <TrashIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Delete
                                    </button>
                                </MenuItem>
                                </div>
                            </MenuItems>
                            </transition>
                        </Menu>
                        <div v-if="casesEditMode[cIndx]" class="ml-2">
                            <button @click.prevent="caseEditRef = c,editModals.askForUpdateCase = true, currentCaseIndex = cIndx" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                Save
                                <CheckIcon class="ml-2 -mr-0.5 h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                        
                        
                        
                    </div>
                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Completed</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center justify-between">
                                <Switch :disabled="!casesEditMode[cIndx]" v-model="c.completed" :class="[ !casesEditMode[cIndx] ? 'opacity-50 pointer-events-none' : '', c.completed ? 'bg-green-500' : 'bg-red-400', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2']">
                                    <span :class="[c.completed ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                                    <span :class="[c.completed ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                                        <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                        <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                    <span :class="[c.completed ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                                        <svg class="h-3 w-3 text-green-600" fill="currentColor" viewBox="0 0 12 12">
                                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                        </svg>
                                    </span>
                                    </span>
                                </Switch>
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Reason</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center justify-between">
                                <div :class="!casesEditMode[cIndx] ? 'opacity-50 pointer-events-none select-none' : 'select-none'">
                                    <!-- OLD VERSION -->
                                    <!-- <select v-model="c.reason" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                                        <option v-for="(reason,rsnIndx) in c.acceptance === 'accepted' ? reasonsList : reasonsDenyList" :key="rsnIndx" :value="reason.name">{{reason.name}}</option>
                                    </select> -->
                                    <Listbox :disabled="!casesEditMode[cIndx]" as="div" v-model="c.reason" class="w-80">
                                        <div class="relative">
                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                            <span class="flex items-center truncate">{{c.reason}}</span>
                                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                            <ListboxOption as="template" v-for="(reason,rsnIndx) in c.acceptance === 'accepted' ? reasonsList : reasonsDenyList" :key="rsnIndx" :value="reason.name" v-slot="{ active, selected }">
                                                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{reason.name}}</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                                </li>
                                            </ListboxOption>
                                            </ListboxOptions>
                                        </transition>
                                        </div>
                                    </Listbox>
                                </div>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Planned action</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
                                <div :class="!casesEditMode[cIndx] ? 'opacity-50 pointer-events-none select-none flex' : 'select-none'">
                                    <!-- OLD VERSION -->
                                    <!-- <select v-model="c.action" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md" v-if="c.acceptance === 'accepted'">
                                        <option v-for="(action,actIndx) in actionsList" :key="actIndx" :value="action.name">{{action.name}}</option>
                                    </select> -->
                                    <Listbox :disabled="!casesEditMode[cIndx]" v-if="c.acceptance === 'accepted'" as="div" class="w-44" v-model="c.action">
                                        <div class="relative">
                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                            <span class="flex items-center truncate">{{c.action}}</span>
                                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                            <ListboxOption as="template" v-for="(action,actIndx) in actionsList" :key="actIndx" :value="action.name" v-slot="{ active, selected }">
                                                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{action.name}}</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                                </li>
                                            </ListboxOption>
                                            </ListboxOptions>
                                        </transition>
                                        </div>
                                    </Listbox>
                                    <span v-if="c.acceptance === 'declined'" class="ml-2">
                                        {{c.action}}
                                    </span>
                                </div>
                            </dd>
                        </div>

                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Action preferred by client</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
                                <div :class="!casesEditMode[cIndx] ? 'opacity-50 pointer-events-none select-none' : 'select-none'">
                                    <!-- OLD VERSION -->
                                    <!-- <select v-model="c.preferredActionByClient" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                                        <option v-for="(action,actIndx) in actionsPreferredByClientList" :key="actIndx" :value="action.name">{{action.name}}</option>
                                    </select> -->
                                    <Listbox :disabled="!casesEditMode[cIndx]" as="div" class="w-44" v-model="c.preferredActionByClient">
                                        <div class="relative">
                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                            <span class="flex items-center truncate">{{c.preferredActionByClient}}</span>
                                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                            <ListboxOption as="template" v-for="(action,actIndx) in actionsPreferredByClientList" :key="actIndx" :value="action.name" v-slot="{ active, selected }">
                                                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{action.name}}</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                                </li>
                                            </ListboxOption>
                                            </ListboxOptions>
                                        </transition>
                                        </div>
                                    </Listbox>
                                </div>
                            
                            </dd>
                        </div>

                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Complaint cost</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                <span>{{ c.acceptance === 'accepted' ? c.item.complaintCostAtAddingTime.toFixed(2) : '0.00'}} USD</span>
                                <button v-if="casesEditMode[cIndx] && c.acceptance === 'accepted' && beforeEditComplaint.cases[cIndx].acceptance === 'accepted'" @click.prevent="updateComplaintCostFromProduct(c)" class="ml-4 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                    Update from product
                                    <RefreshIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                                </button>
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Description</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center justify-between">
                                <div v-if="!casesEditMode[cIndx]">
                                    {{c.description.length === 0 ? "---" : c.description}}
                                </div>
                                <div v-if="casesEditMode[cIndx]" class="w-full">
                                     <textarea rows="8" v-model="c.description" name="comment" id="comment" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" />
                                </div>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" v-if="evaluate(this.$store.state.userSubscription.items[0].price.product.metadata.pdfUpload)">
                            <dt class="text-sm font-medium text-gray-500 select-none">PDF</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center justify-between">
                                <div v-if="c.files.pdfs.length === 0 && pdfHolder[c.id.toString()].length === 0" class="text-xs opacity-60">No files</div>
                                <div class="flex items-center" v-for="(pdf,pdfIndx) in c.files.pdfs" :key="pdfIndx">
                                    <DocumentIcon class="h-4 w-4 mr-1 opacity-60" aria-hidden="true" />
                                    <span class="text-xs opacity-60">{{pdf.originalName.substring(0,100)}}</span>
                                    <a class="text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer whitespace-nowrap" :href="pdf.url" target="_blank">Open</a>
                                    <div class="text-xs ml-4 mr-4 text-red-500 underline hover:text-red-700 cursor-pointer whitespace-nowrap" v-if="casesEditMode[cIndx] && isIncludedInFilesToDelete(pdf,c) === false" @click.prevent="markFileAsToDelete(pdf,'pdf',c,pdfIndx)">Mark to delete</div>
                                    <div class="text-xs ml-4 mr-4 text-yellow-500 underline hover:text-yellow-700 cursor-pointer whitespace-nowrap" v-if="casesEditMode[cIndx] && isIncludedInFilesToDelete(pdf,c) === true" @click.prevent="unmarkFileFromDelete(pdf,c)">Restore PDF</div>
                                </div>
                                <div  class="flex items-center" v-if="c.files.pdfs.length === 0 && pdfHolder[c.id.toString()].length > 0">
                                    <DocumentIcon class="h-4 w-4 mr-1 opacity-60" aria-hidden="true" />
                                    <span class="text-xs opacity-60">{{pdfHolder[c.id.toString()][0].file.name}} <span class="opacity-50">(Not uploaded)</span></span>
                                    <span class="text-xs ml-4 mr-4 text-red-500 underline hover:text-red-700 cursor-pointer whitespace-nowrap" @click.prevent="resetHolder(c.id, $refs[`pdfUpldr${cIndx}`])">Delete</span>
                                </div>
                                <div v-if="casesEditMode[cIndx]">
                                    <input type="file" accept="application/pdf" v-show="false" :ref="`pdfUpldr${cIndx}`" @change="addToPDFHolder($event,c.id)">  
                                    <button type="button" @click.prevent="triggerPDFUploaderClick(`pdfUpldr${cIndx}`, c.files.pdfs.length)"  :class="{'whitespace-nowrap mt-2 mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-blue-700 hover:bg-blue-200 bg-blue-100 focus:ring-blue-500': true, 'opacity-30 pointer-events-none select-none' :  c.files.pdfs.length + pdfHolder[c.id.toString()].length > 0}"><DocumentIcon class="h-4 w-4 mr-1" aria-hidden="true" />Attach PDF</button>
                                </div>
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Images</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center justify-between">
                                <div v-if="c.files.images.length === 0 && imgHolder[c.id.toString()].length === 0" class="text-xs opacity-60">No files</div>
                                <div>
                                    <div class="flex items-center mb-2" v-for="(img,imgIndx) in c.files.images" :key="imgIndx">
                                        <PhotographIcon class="h-4 w-4 mr-1 opacity-60" aria-hidden="true" />
                                        <span class="text-xs opacity-60">{{img.originalName.substring(0,100)}}</span>
                                        <a class="text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer" :href="img.url" target="_blank">Open</a>
                                        <div class="text-xs ml-4 text-red-500 underline hover:text-red-700 cursor-pointer" v-if="casesEditMode[cIndx] && isIncludedInFilesToDelete(img,c) === false" @click.prevent="markFileAsToDelete(img,'img',c,imgIndx)">Mark to delete</div>
                                        <div class="text-xs ml-4 text-yellow-500 underline hover:text-yellow-700 cursor-pointer" v-if="casesEditMode[cIndx] && isIncludedInFilesToDelete(img,c) === true" @click.prevent="unmarkFileFromDelete(img,c)">Restore image</div>
                                    </div>
                                    <div v-if="imgHolder[c.id.toString()].length > 0">
                                        <div class="flex items-center mb-2  " v-for="(img,imgIndx) in imgHolder[c.id.toString()]" :key="imgIndx">
                                            <PhotographIcon class="h-4 w-4 mr-1 opacity-60" aria-hidden="true" />
                                            <span class="text-xs opacity-60">{{img.file.name.substring(0,100)}} <span class="opacity-50">(Not uploaded)</span></span>
                                            <div class="text-xs ml-4 text-red-500 underline hover:text-red-700 cursor-pointer" @click.prevent="imgHolder[c.id].splice(imgIndx,1)">Delete</div>
                                        </div>                      
                                    </div>
                                </div>



                                <div  v-if="casesEditMode[cIndx] && !(this.imgQty - c.files.images.length - this.imgHolder[c.id.toString()].length < 0)">
                                    <input type="file" accept="image/*" v-show="false" :ref="`imgUpldr${cIndx}`" @change="addToImgHolder($event,c.id,cIndx)">
                                    <button type="button" @click.prevent="triggerImgUploaderClick(`imgUpldr${cIndx}`, c.files.images.length,imgHolder[c.id].length)" :class="{'mt-2 mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-blue-700 hover:bg-blue-200 bg-blue-100 focus:ring-blue-500': true, 'opacity-30 pointer-events-none select-none' : this.imgQty - c.files.images.length - this.imgHolder[c.id.toString()].length <= 0}"><CameraIcon class="h-4 w-4 mr-1" aria-hidden="true" />Add image ({{this.imgQty - c.files.images.length - this.imgHolder[c.id.toString()].length}} left)</button>
                                </div>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            <div class="mt-4 mb-4" v-if="this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingComplaints">
                <button type="button" @click.prevent="editModals.caseCreator = true" class="inline-flex items-center rounded-full border border-transparent bg-blue-600 px-3 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Add new case</button>
            </div>
        </div>

            <!-- CHARGEBACK -->
        <div v-if="currentContainerNum === 3">

            <!-- FORM -->
            <div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                <div class="px-4 py-5 sm:px-6 flex items-center justify-between">
                    <h3 class="sm:text-lg leading-6 font-medium text-gray-900 select-none">Chargeback <span v-if="!complaint.chargeback.applicable" class="text-xs text-gray-500">(Not applicable)</span></h3>
                    <div v-if="this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingComplaints">
                        <button v-if="!complaint.completed" type="button" @click.prevent="editModals.chargebackModal = true" class="inline-flex items-center px-2 py-1 sm:px-3 sm:py-2 text-xs sm:text-sm border border-gray-300 shadow-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Edit
                            <PencilIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                        </button>  
                    </div>

                </div>
                
                <div :class="{'border-t border-gray-200': true, 'opacity-20': !complaint.chargeback.applicable}">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Paid</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span :class="[complaint.chargeback.done ? 'text-green-500' : 'text-red-500']">{{!complaint.chargeback.applicable  ? "Not applicable" : complaint.chargeback.done ? 'Yes' : 'No' }}</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">IBAN</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span>{{!complaint.chargeback.applicable  ? "Not applicable" : complaint.chargeback.iban.length > 0 ? complaint.chargeback.iban : "---" }}</span>
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">SWIFT</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                <span>{{!complaint.chargeback.applicable  ? "Not applicable" : complaint.chargeback.swift.length > 0 ? complaint.chargeback.swift : "---"}}</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Recipient</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                <span>{{!complaint.chargeback.applicable  ? "Not applicable" : complaint.chargeback.recipient.length > 0 ? complaint.chargeback.recipient : "---"}}</span>
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Full address</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                <span>{{!complaint.chargeback.applicable  ? "Not applicable" : complaint.chargeback.address.length > 0 ? complaint.chargeback.address : "---" }}</span>
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Transfer title</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span>{{!complaint.chargeback.applicable  ? "Not applicable" : complaint.chargeback.transferTitle.length > 0 ? complaint.chargeback.transferTitle : "---" }}</span>
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Amount</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                <span>{{!complaint.chargeback.applicable  ? "Not applicable" : `${complaint.chargeback.amount.toFixed(2)} ${complaint.chargeback.currency}` }}</span>
                            </dd>
                        </div>

                    </dl>
                </div>
            </div> 

        </div>

        <div v-if="currentContainerNum === 4">

            <!-- FORM -->
            <div class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                <div class="px-4 py-5 sm:px-6 flex items-center justify-between">
                    <h3 class="sm:text-lg leading-6 font-medium text-gray-900 select-none">Delivery <span v-if="!complaint.delivery.type !== 'Not applicable' && complaint.delivery.done" class="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">Completed</span> <span v-if="!complaint.delivery.type === 'Not applicable'" class="text-xs text-gray-500">(Not applicable)</span></h3>
                    <div>
                        <button v-if="!complaint.completed && this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingComplaints" type="button" @click.prevent="editModals.deliveryModal = true" class="inline-flex items-center px-2 py-1 sm:px-3 sm:py-2 text-xs sm:text-sm border border-gray-300 shadow-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Edit
                            <PencilIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                        </button>  
                    </div>

                </div>
                
                <div :class="{'border-t border-gray-200': true, 'opacity-20': !complaint.delivery.type === 'Not applicable'}">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Type</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span>{{complaint.delivery.type}}</span>
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Courier</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                {{complaint.delivery.type === 'Not applicable' ? 'Not applicable' : complaint.delivery.courier.length>0 ? complaint.delivery.courier : "---" }}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Name</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                {{complaint.delivery.type === 'Not applicable' ? 'Not applicable' : complaint.delivery.name.length>0 ? complaint.delivery.name : "---" }}
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Address</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                {{complaint.delivery.type === 'Not applicable' ? 'Not applicable' : complaint.delivery.address.length>0 ? complaint.delivery.address : "---" }}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">City</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{complaint.delivery.type === 'Not applicable' ? 'Not applicable' : complaint.delivery.city.length>0 ? complaint.delivery.city : "---" }}
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Post code</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                {{complaint.delivery.type === 'Not applicable' ? 'Not applicable' : complaint.delivery.postCode.length>0 ? complaint.delivery.postCode : "---" }}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Country</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{complaint.delivery.type === 'Not applicable' ? 'Not applicable' : complaint.delivery.country.length>0 ? complaint.delivery.country : "---" }}
                            </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500 select-none">Phone number</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                {{complaint.delivery.type === 'Not applicable' ? 'Not applicable' : complaint.delivery.phoneNum.length>0 ? complaint.delivery.phoneNum : "---" }}
                            </dd>
                        </div>

                    </dl>
                </div>
            </div> 

        </div>




        <CaseCreator :open="editModals.caseCreator" @close="editModals.caseCreator = false" :complaintData="{id: complaint.id, status: complaint.completed, otherCases: complaint.cases, complaint: this.complaint}" @refreshComplaint="getComplaintById()"></CaseCreator>
        <ExternalIdsModal @reloadComplaint="getComplaintById()" @reindexElasticSearch="elasticDocUpdate()" :dataToModify="complaint.externalIds" :dbInfo="{docId: complaint.id, key: 'externalIds'}" :open="editModals.externalModifiers" @close="editModals.externalModifiers = false"></ExternalIdsModal>
        <ChargebackModal v-if="editModals.chargebackModal" @reloadComplaint="getComplaintById()" :dataToModify="complaint.chargeback" :dbInfo="{docId: complaint.id, key: 'chargeback', createdDate: this.complaint.createdDate}" :open="editModals.chargebackModal" @closeModal="editModals.chargebackModal = false"></ChargebackModal>
        <DeliveryModal v-if="editModals.deliveryModal" @reloadComplaint="getComplaintById()" :dataToModify="complaint.delivery" :dbInfo="{docId: complaint.id, key: 'delivery', createdDate: this.complaint.createdDate}" :open="editModals.deliveryModal" @closeModal="editModals.deliveryModal = false"></DeliveryModal>
        <PlannedFinalizationModal @reloadComplaint="getComplaintById()" :dataToModify="{assignedTo: complaint.assignedTo, scheduledEnd: complaint.scheduledEnd, priority: complaint.priority }" :dbInfo="{docId: complaint.id, key: 'externalIds', createdDate: complaint.createdDate}" :open="editModals.plannedFinalization" @close="editModals.plannedFinalization = false"></PlannedFinalizationModal>
        <AskModal v-if="editModals.askForUpdateCase" @accept="updateCase()" :modalData="caseUpdateModalData" @closeModal="editModals.askForUpdateCase = false"></AskModal>
        <AskModal v-if="deleteAsk" @accept="deleteComplaint" :modalData="deleteModalData" @closeModal="deleteAsk = false"></AskModal>
        <AskModal v-if="editModals.askForCasesDelete" @accept="deleteCase" :modalData="caseModalData" @closeModal="editModals.askForCasesDelete = false"></AskModal>
        <AskModal v-if="editModals.askForClientReload" @accept="reloadClient" :modalData="reloadClientModalData" @closeModal="editModals.askForClientReload = false"></AskModal>
        <AttribsAtAddingTime v-if="modal.open" :item="modal.selectedCaseItem" @close="closeAttributeModal"></AttribsAtAddingTime>
        <NotificationsModal v-if="notifyModal.open" @closeModal="closeNotifyModal" @updateNotifications="updateNotifications" :complaint="this.complaint"></NotificationsModal>
    </div>
</template>

<script>
    import EmptyState from "@/components/EmptyState.vue";
    import axios from 'axios';
    import moment from 'moment';
    import CaseCreator from '@/components/CaseCreatorModal.vue'
    import AskModal from '@/components/AlertModal.vue';
    import {firebase,storage,db} from "@/firebase/gfbconf.js"
    import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
    import {ExternalLinkIcon,ChevronDownIcon,DocumentIcon,PhotographIcon,PencilIcon,UserIcon,RefreshIcon,TrashIcon,CheckIcon,CameraIcon,BellIcon, ChatAlt2Icon} from '@heroicons/vue/outline';
    import {SelectorIcon,ExclamationCircleIcon,CheckCircleIcon, ExclamationIcon as ExclamationTriangleIcon } from "@heroicons/vue/solid"
    import AttribsAtAddingTime from '@/components/AttribsAtAddingTime.vue';
    import ExternalIdsModal from '@/components/ExternalIdsModal.vue';
    import ChargebackModal from '@/components/ChargebackModalEdit.vue';
    import DeliveryModal from '@/components/ModifyDeliveryModal.vue';
    import PlannedFinalizationModal from '@/components/PlannedFinalizationModal.vue';
    import NotificationsModal from '@/components/notificationsModal.vue';
    import { Switch, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
    export default {
        name: "EditComplaints",
        data()
        {
            return {
                caseEditRef: null,
                // Img upload settings
                perImgSizeMB: 4,
                imgQty: 4,
                // Pdf uplaod settings
                perPDFSizeMB: 2,
                filesMarkedToDelete: {},
                showSearchProduct: false,
                caseInEdit: null,
                caseIndxToDelete: -1,
                currentCaseIndex: null,
                complaintCostsBackup: {},
                beforeEditComplaint: {},
                actionsPreferredByClientList: [],
                casesBackup: [],
                casesEditMode: [],
                reasonsList: [],
                reasonsDenyList: [],
                pdfHolder: {},
                imgHolder: {},
                actionsList: [],
                modal: {
                    open: false,
                    selectedCaseItem: null,
                },
                notifyModal: {
                    open: false,
                },
                editModals: {
                    deliveryModal: false,
                    caseCreator: false,
                    askForCasesDelete: false,
                    askForClientReload: false,
                    externalModifiers: false,
                    plannedFinalization: false,
                    askForUpdateCase: false,
                    chargebackModal: false
                },
                deleteAsk: false,
                reloadClientModalData: {
                    headerText: "Update client data",
                    bodyText: "Are you sure that you want to update the customer data from his current profile?",
                    acceptButtonText: "Update"
                },
                caseUpdateModalData: {
                    headerText: "Update case",
                    bodyText: "Are you sure that you want to update this case?",
                    acceptButtonText: "Update"
                },
                caseModalData: {
                    headerText: "Delete case",
                    bodyText: "Are you sure to delete this case? Data will be removed permanently.",
                    acceptButtonText: "Agree"
                },
                deleteModalData: {
                    headerText: "Delete complaint",
                    bodyText: "Are you sure to delete this complaint? Data will be removed permanently.",
                    acceptButtonText: "Agree"
                },
                currentContainerNum: 1,
                complaint: null,
                tabs: [{ name: 'Basic Information', container: 1 , current: true },{ name: `Cases`, container: 2, current: false },{ name: `Chargeback`, container: 3, current: false },{ name: `Delivery`, container: 4, current: false }],
            }
        },
        components: {ExternalLinkIcon,ChatAlt2Icon, SelectorIcon,BellIcon,Listbox, ListboxButton, ListboxOption, ListboxOptions, DeliveryModal,ChargebackModal,CameraIcon,CheckIcon,CaseCreator,EmptyState,ChevronDownIcon,Menu, MenuButton, MenuItem, MenuItems, TrashIcon,Switch,RefreshIcon,UserIcon,ExclamationTriangleIcon,PlannedFinalizationModal, AskModal, ExclamationCircleIcon, CheckCircleIcon, DocumentIcon,PhotographIcon,AttribsAtAddingTime,PencilIcon,ExternalIdsModal,NotificationsModal},
        async created()
        {
            this.checkPlan();
            await this.getComplaintById();
            await this.getReasons();
            await this.getActions();
            // console.log(this.complaint)
            // console.log(this.$store.state.systemData.users[0]);
        },
        methods:
        {
            openChat(){
                this.$router.push(`/dashboard/chats/view?id=${this.complaint.id}`);
            },
            async sendAttendantNotify(currentTime){
                try{
                        let attendantData = null
                        if(this.complaint.assignedTo.ref !== null){
                            let promise = await this.complaint.assignedTo.ref.get()
                            if(promise.exists){
                                attendantData = promise.data()
                                attendantData.displayName = `${attendantData.name} ${attendantData.surname}`
                                // console.log(attendantData)
                            }else{
                                attendantData = {
                                email: this.$store.state.systemData.users[0].email,
                                displayName: this.$store.state.systemData.users[0].displayName
                                }
                            }
                        }else{
                            attendantData = {
                                email: this.$store.state.systemData.users[0].email,
                                displayName: this.$store.state.systemData.users[0].displayName
                                }
                        }
                        let response = await axios.post('https://europe-central2-complaia.cloudfunctions.net/api/sendEmail', {
                            attendantEmail: `${attendantData.email}`,
                            to: `${this.complaint.client.email}`,
                            messageType: "attendantInformation",
                            payload: {
                            complaintLink: `http://client.complaia.com/?portal=${this.$store.state.systemData.portal.key}&complaint=${this.complaint.portalData.formId}`,
                            clientName: `${this.complaint.client.name.length > 0 ? this.complaint.client.name : 'User'}`,
                            attendantName: `${attendantData.displayName.length > 0 ? attendantData.displayName : 'attendant'}`
                        }
                        });
                        if(response.statusText === "OK"){
                            await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaint.id.toString()).update({
                            'notifications.attendantInformation': currentTime
                            })
                            this.complaint.notifications.attendantInformation = currentTime;
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Notification has been sent!",
                                subheader: `Notification about assigned attendant has been sent to the client.`,
                                success: true
                            });
                            // Add event to event list.
                            this.$store.state.userData.system.ref.collection("Events").add(
                            {
                            user: {
                                name: this.$store.state.user.displayName,
                                photo: this.$store.state.user.photoURL
                            },
                            creationDate: moment().toDate(),
                            eventName: `Notification about attendant has been sent. Complaint: ${this.complaint.id}`
                            }).catch((error)=>
                            {
                            console.log(error);
                            });
                        }else{
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Oops! Something went wrong!",
                                subheader: `Cannot send notification`,
                                success: false
                            });
                        }
                    }
                    catch(error)
                    {
                        console.log(error);
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Oops! Something went wrong!",
                            subheader: `Cannot send notification.`,
                            success: false
                        });
                    }
            },
            async sendUpdateNotify(currentTime){
                try{
                        let attendantData = null
                        if(this.complaint.assignedTo.ref !== null){
                            let promise = await this.complaint.assignedTo.ref.get()
                            if(promise.exists){
                                attendantData = promise.data()
                            }else{
                                attendantData = {
                                email: this.$store.state.systemData.users[0].email
                                }
                            }
                        }else{
                            attendantData = {
                                email: this.$store.state.systemData.users[0].email
                                }
                        }
                        let response = await axios.post('https://europe-central2-complaia.cloudfunctions.net/api/sendEmail', {
                            attendantEmail: `${attendantData.email}`,
                            to: `${this.complaint.client.email}`,
                            messageType: "complaintUpdated",
                            payload: {
                            complaintLink: `http://client.complaia.com/?portal=${this.$store.state.systemData.portal.key}&complaint=${this.complaint.portalData.formId}`,
                            clientName: `${this.complaint.client.name.length > 0 ? this.complaint.client.name : 'User'}`,
                            currentStatus: `${this.complaint.completed ? 'Closed' : 'Processing'}`
                        }
                        });
                        if(response.statusText === "OK"){
                            await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaint.id.toString()).update({
                            'notifications.complaintUpdate': currentTime
                            })
                            this.complaint.notifications.complaintUpdate = currentTime;
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Notification has been sent!",
                                subheader: `Notification about update in complaint has been sent to the client.`,
                                success: true
                            });
                            // Add event to event list.
                            this.$store.state.userData.system.ref.collection("Events").add(
                            {
                            user: {
                                name: this.$store.state.user.displayName,
                                photo: this.$store.state.user.photoURL
                            },
                            creationDate: moment().toDate(),
                            eventName: `Notification about update has been sent. Complaint: ${this.complaint.id}`
                            }).catch((error)=>
                            {
                            console.log(error);
                            });
                        }else{
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Oops! Something went wrong!",
                                subheader: `Cannot send notification`,
                                success: false
                            });
                        }
                    }
                    catch(error)
                    {
                        console.log(error);
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Oops! Something went wrong!",
                            subheader: `Cannot send notification.`,
                            success: false
                        });
                    }
            },
            async sendCreationNotify(currentTime){
                try{
                        let attendantData = null
                        if(this.complaint.assignedTo.ref !== null){
                            let promise = await this.complaint.assignedTo.ref.get()
                            if(promise.exists){
                                attendantData = promise.data()
                            }else{
                                attendantData = {
                                email: this.$store.state.systemData.users[0].email
                                }
                            }
                        }else{
                            attendantData = {
                                email: this.$store.state.systemData.users[0].email
                                }
                        }
                        let response = await axios.post('https://europe-central2-complaia.cloudfunctions.net/api/sendEmail', {
                            attendantEmail: `${attendantData.email}`,
                            to: `${this.complaint.client.email}`,
                            messageType: "complaintCreate",
                            payload: {
                            complaintLink: `http://client.complaia.com/?portal=${this.$store.state.systemData.portal.key}&complaint=${this.complaint.portalData.formId}`,
                            clientName: `${this.complaint.client.name.length > 0 ? this.complaint.client.name : 'User'}`
                        }
                        });
                        if(response.statusText === "OK"){
                            await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaint.id.toString()).update({
                            'notifications.complaintCreation': currentTime
                            })
                            this.complaint.notifications.complaintCreation = currentTime;
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Notification has been sent!",
                                subheader: `Notification about complaint creation has been sent to the client.`,
                                success: true
                            });
                            // Add event to event list.
                            this.$store.state.userData.system.ref.collection("Events").add(
                            {
                            user: {
                                name: this.$store.state.user.displayName,
                                photo: this.$store.state.user.photoURL
                            },
                            creationDate: moment().toDate(),
                            eventName: `Notification about complaint creation has been sent. Complaint: ${this.complaint.id}`
                            }).catch((error)=>
                            {
                            console.log(error);
                            });


                        }else{
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Oops! Something went wrong!",
                                subheader: `Cannot send notification`,
                                success: false
                            });
                        }
                    }
                    catch(error)
                    {
                        console.log(error);
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Oops! Something went wrong!",
                            subheader: `Cannot send notification.`,
                            success: false
                        });
                    }
            },
            async updateNotifications(type){
                if(this.complaint.notifications !== undefined){
                    let currentTime = moment().unix();
                        if(type === 'creation'){
                            this.sendCreationNotify(currentTime)
                        }
                        if(type === 'update'){
                            this.sendUpdateNotify(currentTime)
                        }
                        if(type === 'attendant'){
                            this.sendAttendantNotify(currentTime)
                        }
                }
                if(this.complaint.notifications === undefined){
                    let preparedTemplate = {
                        attendantInformation: null,
                        complaintUpdate: null,
                        complaintCreation: null
                    }
                    try{
                        await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaint.id.toString()).update({
                            'notifications': preparedTemplate
                        });
                        this.complaint.notifications = preparedTemplate
                        let currentTime = moment().unix();
                        if(type === 'creation'){
                            this.sendCreationNotify(currentTime)
                        }
                        if(type === 'update'){
                            this.sendUpdateNotify(currentTime)
                        }
                        if(type === 'attendant'){
                            this.sendAttendantNotify(currentTime)
                        }
                    }
                    catch(error)
                    {
                        console.log(error);
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Oops! Something went wrong!",
                            subheader: `Cannot add notification to database.`,
                            success: false
                        });
                    }
                }
            },
            closeNotifyModal(){
                this.notifyModal.open = false
            },
            evaluate(string) {
            return eval(string)
            },
          checkPlan() {
            if(this.$store.state.userSubscription.items[0].price.product.metadata.planName === "starter") {
              this.perImgSizeMB = parseInt(this.$store.state.userSubscription.items[0].price.product.metadata.imageSizePerFileMb)
              this.imgQty = parseInt(this.$store.state.userSubscription.items[0].price.product.metadata.imagesPerComplaint)
            } else if(this.$store.state.userSubscription.items[0].price.product.metadata.planName === "standard") {
              this.perImgSizeMB = parseInt(this.$store.state.userSubscription.items[0].price.product.metadata.imageSizePerFileMb)
              this.imgQty = parseInt(this.$store.state.userSubscription.items[0].price.product.metadata.imagesPerComplaint)
            } else if(this.$store.state.userSubscription.items[0].price.product.metadata.planName === "pro") {
              this.perImgSizeMB = parseInt(this.$store.state.userSubscription.items[0].price.product.metadata.imageSizePerFileMb)
              this.imgQty = parseInt(this.$store.state.userSubscription.items[0].price.product.metadata.imagesPerComplaint)
            }
          },   
            addToImgHolder(event,holderId,indx)
            {
              if(event.target.files.length === 0) return;
              const file = event.target.files[0];
              const sizeInMb = file.size / 1024 / 1024;
              if(sizeInMb <= this.perImgSizeMB)
              {
                let fileAlreadyAdded = false;
                for(let i=0;i<this.imgHolder[holderId].length;i++)
                {
                  if(this.imgHolder[holderId][i].file.name === file.name)
                  {
                    fileAlreadyAdded = true;
                  }
                }
                if(!fileAlreadyAdded)
                {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                      const content = event.target.result;
                      this.imgHolder[holderId].push({file: file, dataURL: content});
                      this.$refs[`imgUpldr${indx}`][0].value = "";
                      this.$store.commit('setNotification',{
                        show: true,
                        head: "Image has been added!",
                        subheader: ``,
                        success: true
                      }); 
                  };
                  reader.readAsDataURL(file);
                }
                else
                {
                  this.$store.commit('setNotification',{
                    show: true,
                    head: "Cannot add this image!",
                    subheader: `List contains file with same name.`,
                    success: false
                  });                  
                }
              }
              else
              {
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Cannot add this image!",
                  subheader: `File size is more than ${this.perImgSizeMB} MB.`,
                  success: false
                });
              }

            },
            triggerImgUploaderClick(uploaderRefAsText,filesLength, holderLength)
            {
              if(filesLength + holderLength >= this.imgQty) return;
              this.$refs[uploaderRefAsText][0].click();
            },
            resetHolder(id,input)
            {
                input[0].value = '';
                this.pdfHolder[id.toString()] = [];
            },
            addToPDFHolder(event,holderId)
            {
              this.pdfHolder[holderId] = [];
              if(event.target.files.length === 0) return;
              const file = event.target.files[0];
              const sizeInMb = file.size / 1024 / 1024;
              if(sizeInMb <= this.perPDFSizeMB)
              {
                this.pdfHolder[holderId].push({file: file});
                this.$store.commit('setNotification',{
                  show: true,
                  head: "PDF file has been added!",
                  subheader: ``,
                  success: true
                }); 
              }
              else
              {
                  this.$store.commit('setNotification',{
                    show: true,
                    head: "Cannot add this PDF!",
                    subheader: `File size is more than ${this.perPDFSizeMB} MB.`,
                    success: false
                  });                
              }
            },
            triggerPDFUploaderClick(uploaderRefAsText,amount)
            {
                if(amount !== 0) return;
                this.$refs[uploaderRefAsText][0].click();
            },
            async deleteSelectedImagesFromCase(c)
            {
                try{
                    for(let i=0; i< this.filesMarkedToDelete[c.id].length;i++)
                    {
                        let fileToDelete = this.filesMarkedToDelete[c.id][i];
                        // 
                        if(fileToDelete.type === 'pdf')
                        {
                            for(let y=0; y<c.files.pdfs.length;y++)
                            {
                                if(c.files.pdfs[y].path === fileToDelete.file.path)
                                {
                                    await storage.ref().child(fileToDelete.file.path).delete();
                                    c.files.pdfs.splice(y,1);  
                                }
                            }
                        }
                        if(fileToDelete.type === 'img')
                        {
                            
                            for(let y=0; y<c.files.images.length;y++)
                            {
                                if(c.files.images[y].path === fileToDelete.file.path)
                                {
                                    await storage.ref().child(fileToDelete.file.path).delete();
                                    c.files.images.splice(y,1);  
                                }
                            }
                        }
                    }
                }
                catch(error)
                {
                    console.log(error);
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Oops! Something went wrong!",
                        subheader: `Cannot delete marked file.`,
                        success: false
                    });
                }
            },
            async checkForCompletionAndUpdate()
            {
                
                let amountOfCompleted = 0;
                let isComplaintCompleted = false;

                for(let i=0; i<this.complaint.cases.length;i++)
                {
                    if(this.complaint.cases[i].completed === true)
                    {
                        amountOfCompleted = amountOfCompleted+1;
                    }
                }

                if(amountOfCompleted === this.complaint.cases.length)
                {
                    isComplaintCompleted = true;
                }

                if(this.complaintCompleted !== isComplaintCompleted)
                {
                    await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaint.id.toString()).update({
                        'completed': isComplaintCompleted,
                        'portalData.completeDate': isComplaintCompleted === true ? moment().format("YYYY-MM-DD") : null
                    }).catch((error)=>console.log(error));
                }
            },
            async updateCase()
            {
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Saving case..",
                    subheader: `Do not close this tab.`,
                });
                // Must have because of reference
                const pdfArr = this.pdfHolder[this.caseEditRef.id];
                const imgArr = this.imgHolder[this.caseEditRef.id];

                for(let i=0; i< this.filesMarkedToDelete[this.caseEditRef.id].length;i++)
                {
                    let fileToDelete = this.filesMarkedToDelete[this.caseEditRef.id][i];
                    // Find file index
                    for(let y=0; y<this.complaint.cases.length;y++)
                    {
                        if(fileToDelete.type === 'pdf')
                        {
                            for(let z=0; z<this.complaint.cases[y].files.pdfs.length; z++)
                            {
                                if(fileToDelete.file.url === this.complaint.cases[y].files.pdfs[z].url)
                                {
                                    this.complaint.cases[y].files.pdfs.splice(z,1);
                                    await storage.ref().child(fileToDelete.file.path).delete();

                                }
                            }
                        }
                        if(fileToDelete.type === 'img')
                        {
                            for(let z=0; z<this.complaint.cases[y].files.images.length; z++)
                            {
                                if(fileToDelete.file.url === this.complaint.cases[y].files.images[z].url)
                                {
                                    this.complaint.cases[y].files.images.splice(z,1);
                                    await storage.ref().child(fileToDelete.file.path).delete();
                                }
                            }                            
                        }
                    }
                }

                this.filesMarkedToDelete[this.caseEditRef.id.toString()] = [];

                
                const storageRef = storage.ref();
                // Upload PDFs if any new
                for(let y=0; y < pdfArr.length; y++)
                {
                    const file = pdfArr[y].file;
                    const filename = "pdf_" + this.caseEditRef.id.toString() + y.toString();
                    const pdfRef = storageRef.child(`systems/${this.$store.state.userData.system.id}/complaints/${this.complaint.id}/${filename}`);
                    await pdfRef.put(file);
                    const fileUrl = await pdfRef.getDownloadURL();
                    this.caseEditRef.files.pdfs.push({url: fileUrl, name: filename, path: `systems/${this.$store.state.userData.system.id}/complaints/${this.complaint.id}/${filename}`, type: file.type, size: file.size, originalName: file.name});
                }

                // Upload imgs if any new
                  
                for(let y=0; y < imgArr.length; y++)
                {
                const file = imgArr[y].file;
                const filename = "img_" + this.caseEditRef.id.toString() + y.toString();
                const imageRef = storageRef.child(`systems/${this.$store.state.userData.system.id}/complaints/${this.complaint.id}/${filename}`);
                await imageRef.put(file);
                const fileUrl = await imageRef.getDownloadURL();
                this.caseEditRef.files.images.push({url: fileUrl, name: filename, path: `systems/${this.$store.state.userData.system.id}/complaints/${this.complaint.id}/${filename}`, type: file.type, size: file.size, originalName: file.name});
                }

                await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaint.id.toString()).update({
                    'cases': this.complaint.cases
                });

                await this.checkForCompletionAndUpdate()
                await this.updateAnalytics(this.currentCaseIndex)

                this.getComplaintById();
                this.$store.commit('setGlobalLoader',{
                    show: false,
                    head: "Saving case..",
                    subheader: `Do not close this tab.`,
                });

                
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Case updated with success!",
                    subheader: ``,
                    success: true
                });
            },
            async updateAnalytics(caseIndex) {
                const refToYear = this.$store.state.userData.system.ref.collection('Analytics').doc(this.complaint.createdDate.year.toString())

                // prepare analitycs objects
                let yearData = (await refToYear.get()).data()
                let monthData = yearData[moment(this.complaint.createdDate.monthNum, 'MM').format('MMMM')]
                let dayData = monthData.days[this.complaint.createdDate.dayNum]
                if(JSON.stringify(this.complaint.cases) !== JSON.stringify(this.beforeEditComplaint.cases)) {
                    dayData.global.complaints.complaintsWorth = (dayData.global.complaints.complaintsWorth - this.beforeEditComplaint.cases[caseIndex].item.sellPriceAtAddingTime) + this.complaint.cases[caseIndex].item.sellPriceAtAddingTime
                    monthData.global.complaints.complaintsWorth = (monthData.global.complaints.complaintsWorth - this.beforeEditComplaint.cases[caseIndex].item.sellPriceAtAddingTime) + this.complaint.cases[caseIndex].item.sellPriceAtAddingTime
                    yearData.global.complaints.complaintsWorth = (yearData.global.complaints.complaintsWorth - this.beforeEditComplaint.cases[caseIndex].item.sellPriceAtAddingTime) + this.complaint.cases[caseIndex].item.sellPriceAtAddingTime
                    
                    if((this.beforeEditComplaint.cases[caseIndex].item.complaintCostAtAddingTime !== this.complaint.cases[caseIndex].item.complaintCostAtAddingTime && this.complaint.cases[caseIndex].acceptance === 'accepted')) {
                    
                    dayData.global.products.forEach(item => {
                        if(item.id === this.complaint.cases[caseIndex].item.id) {
                            item.costs.forEach((itemCost, index) => {
                                if(itemCost.cost === this.beforeEditComplaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                    itemCost.amount -= 1
                                    if(itemCost.amount <= 0) {
                                        item.costs.splice(index, 1)
                                    }
                                    let itemFound = false
                                    item.costs.forEach(itemNewCost => {
                                        if(itemNewCost.cost === this.complaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                            itemNewCost.amount += 1
                                            itemFound = true
                                        }
                                    })
                                    if(!itemFound) {
                                        item.costs.push({
                                            amount: 1,
                                            cost: this.complaint.cases[caseIndex].item.complaintCostAtAddingTime,
                                        })
                                    }
                                }
                            })
                        }
                    })

                    monthData.global.products.forEach(item => {
                        if(item.id === this.complaint.cases[caseIndex].item.id) {
                            item.costs.forEach((itemCost, index) => {
                                if(itemCost.cost === this.beforeEditComplaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                    itemCost.amount -= 1
                                    if(itemCost.amount <= 0) {
                                        item.costs.splice(index, 1)
                                    }
                                    let itemFound = false
                                    item.costs.forEach(itemNewCost => {
                                        if(itemNewCost.cost === this.complaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                            itemNewCost.amount += 1
                                            itemFound = true
                                        }
                                    })
                                    if(!itemFound) {
                                        item.costs.push({
                                            amount: 1,
                                            cost: this.complaint.cases[caseIndex].item.complaintCostAtAddingTime,
                                        })
                                    }
                                }
                            })
                        }
                    })

                    yearData.global.products.forEach(item => {
                        if(item.id === this.complaint.cases[caseIndex].item.id) {
                            item.costs.forEach((itemCost, index) => {
                                if(itemCost.cost === this.beforeEditComplaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                    itemCost.amount -= 1
                                    if(itemCost.amount <= 0) {
                                        item.costs.splice(index, 1)
                                    }
                                    let itemFound = false
                                    item.costs.forEach(itemNewCost => {
                                        if(itemNewCost.cost === this.complaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                            itemNewCost.amount += 1
                                            itemFound = true
                                        }
                                    })
                                    if(!itemFound) {
                                        item.costs.push({
                                            amount: 1,
                                            cost: this.complaint.cases[caseIndex].item.complaintCostAtAddingTime,
                                        })
                                    }
                                }
                            })
                        }
                    })
                    }
                    if(this.complaint.cases[caseIndex].acceptance !== this.beforeEditComplaint.cases[caseIndex].acceptance || this.complaint.cases[caseIndex].completed !== this.beforeEditComplaint.cases[caseIndex].completed) {

                        if(this.complaint.cases[caseIndex].acceptance === 'declined' && this.complaint.cases[caseIndex].acceptance !== this.beforeEditComplaint.cases[caseIndex].acceptance) {
                            dayData.global.complaints.declined += 1
                            monthData.global.complaints.declined += 1 
                            yearData.global.complaints.declined += 1
                            dayData.global.complaints.complaintsWorth -= this.complaint.cases[caseIndex].item.sellPriceAtAddingTime
                            monthData.global.complaints.complaintsWorth -= this.complaint.cases[caseIndex].item.sellPriceAtAddingTime
                            yearData.global.complaints.complaintsWorth -= this.complaint.cases[caseIndex].item.sellPriceAtAddingTime
                            
                            dayData.global.products.forEach(item => {
                                if(item.id === this.complaint.cases[caseIndex].item.id) {
                                    item.costs.forEach((itemCost, index) => {
                                        if(itemCost.cost === this.complaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                            itemCost.amount -= 1
                                            if(itemCost.amount <= 0) {
                                                item.costs.splice(index, 1)
                                            }
                                        }
                                    })
                                }
                            })

                            monthData.global.products.forEach(item => {
                                if(item.id === this.complaint.cases[caseIndex].item.id) {
                                    item.costs.forEach((itemCost, index) => {
                                        if(itemCost.cost === this.complaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                            itemCost.amount -= 1
                                        }
                                        if(itemCost.amount <= 0) {
                                            item.costs.splice(index, 1)
                                        }
                                    })
                                }
                            })

                            yearData.global.products.forEach(item => {
                                if(item.id === this.complaint.cases[caseIndex].item.id) {
                                    item.costs.forEach((itemCost, index) => {
                                        if(itemCost.cost === this.complaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                            itemCost.amount -= 1
                                        }
                                        if(itemCost.amount <= 0) {
                                            item.costs.splice(index, 1)
                                        }
                                    })
                                }
                            })
                        }

                        
                        if(this.complaint.cases[caseIndex].acceptance === 'accepted' && this.beforeEditComplaint.cases[caseIndex].acceptance === 'declined') {
                           
                            dayData.global.complaints.declined -= 1
                            monthData.global.complaints.declined -= 1 
                            yearData.global.complaints.declined -= 1
                            dayData.global.complaints.complaintsWorth += this.complaint.cases[caseIndex].item.sellPriceAtAddingTime
                            monthData.global.complaints.complaintsWorth += this.complaint.cases[caseIndex].item.sellPriceAtAddingTime
                            yearData.global.complaints.complaintsWorth += this.complaint.cases[caseIndex].item.sellPriceAtAddingTime

                            dayData.global.products.forEach(item => {
                                if(item.id === this.complaint.cases[caseIndex].item.id) {
                                    let itemFound = false
                                    if(item.costs.length === 0) {
                                        item.costs.push({
                                            amount: 1,
                                            cost: this.complaint.cases[caseIndex].item.complaintCostAtAddingTime,
                                        })
                                    } else {
                                        item.costs.forEach(itemCost => {
                                            if(itemCost.cost === this.complaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                                itemCost.amount += 1
                                                itemFound = true
                                            }
                                            if(!itemFound) {
                                                item.costs.push({
                                                    amount: 1,
                                                    cost: this.complaint.cases[caseIndex].item.complaintCostAtAddingTime,
                                                })
                                            }
                                        })
                                    }
                                }
                            })

                            monthData.global.products.forEach(item => {
                                if(item.id === this.complaint.cases[caseIndex].item.id) {
                                    let itemFound = false
                                    if(item.costs.length === 0) {
                                        item.costs.push({
                                            amount: 1,
                                            cost: this.complaint.cases[caseIndex].item.complaintCostAtAddingTime,
                                        })
                                    } else {
                                        item.costs.forEach(itemCost => {
                                            if(itemCost.cost === this.complaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                                itemCost.amount += 1
                                                itemFound = true
                                            }
                                            if(!itemFound) {
                                                item.costs.push({
                                                    amount: 1,
                                                    cost: this.complaint.cases[caseIndex].item.complaintCostAtAddingTime,
                                                })
                                            }
                                        })
                                    }
                                }
                            })
                            yearData.global.products.forEach(item => {
                                if(item.id === this.complaint.cases[caseIndex].item.id) {
                                    let itemFound = false
                                    if(item.costs.length === 0) {
                                        item.costs.push({
                                            amount: 1,
                                            cost: this.complaint.cases[caseIndex].item.complaintCostAtAddingTime,
                                        })
                                    } else {
                                        item.costs.forEach(itemCost => {
                                            if(itemCost.cost === this.complaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                                itemCost.amount += 1
                                                itemFound = true
                                            }
                                            if(!itemFound) {
                                                item.costs.push({
                                                    amount: 1,
                                                    cost: this.complaint.cases[caseIndex].item.complaintCostAtAddingTime,
                                                })
                                            }
                                        })
                                    }
                                }
                            })
                            
                        }

                        let complaintFinished = true;
                        let beforeEditComplaintFinished = true;

                        this.complaint.cases.forEach(item => {
                            if(!item.completed) {
                                complaintFinished = false
                            }
                        })

                        this.beforeEditComplaint.cases.forEach(item => {
                            if(!item.completed) {
                                beforeEditComplaintFinished = false
                            }
                        })

                        if(!beforeEditComplaintFinished && complaintFinished) {
                            dayData.global.complaints.active -= 1
                            monthData.global.complaints.active -= 1 
                            yearData.global.complaints.active -= 1
                            dayData.global.complaints.finished += 1
                            monthData.global.complaints.finished += 1 
                            yearData.global.complaints.finished += 1
                        }

                        if(beforeEditComplaintFinished && !complaintFinished) {
                            dayData.global.complaints.active += 1
                            monthData.global.complaints.active += 1 
                            yearData.global.complaints.active += 1
                            dayData.global.complaints.finished -= 1
                            monthData.global.complaints.finished -= 1 
                            yearData.global.complaints.finished -= 1
                        }

                    } 
                    await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc(this.complaint.createdDate.year.toString()).set(yearData);
                }
                this.currentCaseIndex = null

            },
            async deleteCaseFromAnalitycs(caseIndex, yearData, monthData, dayData) {

                let updateDb = false
                if(yearData === undefined) {
                    const refToYear = this.$store.state.userData.system.ref.collection('Analytics').doc(this.complaint.createdDate.year.toString())

                    // prepare analitycs objects
                    yearData = (await refToYear.get()).data()
                    monthData = yearData[moment(this.complaint.createdDate.monthNum, 'MM').format('MMMM')]
                    dayData = monthData.days[this.complaint.createdDate.dayNum]
                    updateDb = true
                }
                if(this.complaint.cases[caseIndex].acceptance === "declined") {
                    dayData.global.complaints.declined -= 1
                    monthData.global.complaints.declined -= 1 
                    yearData.global.complaints.declined -= 1
                }

                if(this.complaint.cases[caseIndex].acceptance === "accepted") {
                    
                    dayData.global.complaints.complaintsWorth -= this.complaint.cases[caseIndex].item.sellPriceAtAddingTime
                    monthData.global.complaints.complaintsWorth -= this.complaint.cases[caseIndex].item.sellPriceAtAddingTime
                    yearData.global.complaints.complaintsWorth -= this.complaint.cases[caseIndex].item.sellPriceAtAddingTime

                    dayData.global.products.forEach(item => {
                        if(item.id === this.complaint.cases[caseIndex].item.id) {
                            item.costs.forEach(itemCost => {
                                if(itemCost.cost === this.complaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                    itemCost.amount -= 1
                                }
                            })
                        }
                    })

                    monthData.global.products.forEach(item => {
                        if(item.id === this.complaint.cases[caseIndex].item.id) {
                            item.costs.forEach(itemCost => {
                                if(itemCost.cost === this.complaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                    itemCost.amount -= 1
                                }
                            })
                        }
                    })

                    yearData.global.products.forEach(item => {
                        if(item.id === this.complaint.cases[caseIndex].item.id) {
                            item.costs.forEach(itemCost => {
                                if(itemCost.cost === this.complaint.cases[caseIndex].item.complaintCostAtAddingTime) {
                                    itemCost.amount -= 1
                                }
                            })
                        }
                    })

                    }

                let complaintFinished = true;
                let complaintFinishedBeforeDeleteCase = true;

                this.complaint.cases.forEach((item, index) => {
                    if(!item.completed && index !== caseIndex) {
                        complaintFinished = false
                    }
                })

                this.complaint.cases.forEach(item => {
                    if(!item.completed) {
                        complaintFinishedBeforeDeleteCase = false
                    }
                })

                if(complaintFinished && !complaintFinishedBeforeDeleteCase && updateDb) {
                    dayData.global.complaints.active -= 1
                    monthData.global.complaints.active -= 1 
                    yearData.global.complaints.active -= 1
                    dayData.global.complaints.finished += 1
                    monthData.global.complaints.finished += 1 
                    yearData.global.complaints.finished += 1
                }

                if(!complaintFinished && complaintFinishedBeforeDeleteCase && updateDb) {
                    dayData.global.complaints.active += 1
                    monthData.global.complaints.active += 1 
                    yearData.global.complaints.active += 1
                    dayData.global.complaints.finished -= 1
                    monthData.global.complaints.finished -= 1 
                    yearData.global.complaints.finished -= 1
                }

                dayData.global.complaints.casesAmount -= 1
                monthData.global.complaints.casesAmount -= 1 
                yearData.global.complaints.casesAmount -= 1

                if(updateDb) {
                    await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc(this.complaint.createdDate.year.toString()).set(yearData);
                }
            },
            async deleteComplaintFromAnalitycs() {
                const refToYear = this.$store.state.userData.system.ref.collection('Analytics').doc(this.complaint.createdDate.year.toString())

                // prepare analitycs objects
                let yearData = (await refToYear.get()).data()
                let monthData = yearData[moment(this.complaint.createdDate.monthNum, 'MM').format('MMMM')]
                let dayData = monthData.days[this.complaint.createdDate.dayNum]
                for(let i = 0; i < this.complaint.cases.length; i++) {
                  await this.deleteCaseFromAnalitycs(i, yearData, monthData, dayData)
                }
                if(this.complaint.client.meta.originallyCreatedBy.length === 0) {
                    yearData.global.complaints.addedByClient -= 1
                    monthData.global.complaints.addedByClient -= 1
                    dayData.global.complaints.addedByClient -= 1
                } else {
                    yearData.global.complaints.addedManually -= 1
                    monthData.global.complaints.addedManually -= 1
                    dayData.global.complaints.addedManually -= 1
                }

                yearData.global.complaints.priorityStats[this.complaint.priority.replace(/\s+/g, '')] -= 1
                monthData.global.complaints.priorityStats[this.complaint.priority.replace(/\s+/g, '')] -= 1
                dayData.global.complaints.priorityStats[this.complaint.priority.replace(/\s+/g, '')] -= 1
                
                let deliveryType = this.complaint.delivery.type === "Delivery" ? 'delivery' : this.complaint.delivery.type === "Pickup" ? 'pickup' : this.complaint.delivery.type === "Delivery and pickup" ? 'deliveryAndPickup' : ''
                if(deliveryType.length > 0) {
                    yearData.global.complaints.deliveryData[deliveryType] -= 1
                    monthData.global.complaints.deliveryData[deliveryType] -= 1
                    dayData.global.complaints.deliveryData[deliveryType] -= 1
                }
                
                if(this.complaint.chargeback.applicable) {
                    yearData.global.complaints.chargeback.quantity -= 1
                    monthData.global.complaints.chargeback.quantity -= 1
                    dayData.global.complaints.chargeback.quantity -= 1
                    yearData.global.complaints.chargeback.worth -= this.complaint.chargeback.amount
                    monthData.global.complaints.chargeback.worth -= this.complaint.chargeback.amount
                    dayData.global.complaints.chargeback.worth -= this.complaint.chargeback.amount                
                }

                let complaintFinished = true
                this.complaint.cases.forEach(item => {
                    if(!item.completed) {
                        complaintFinished = false
                    }
                })

                if(complaintFinished) {
                    dayData.global.complaints.finished -= 1
                    monthData.global.complaints.finished -= 1 
                    yearData.global.complaints.finished -= 1
                } else if(!complaintFinished) {
                    dayData.global.complaints.active -= 1
                    monthData.global.complaints.active -= 1 
                    yearData.global.complaints.active -= 1
                }

                yearData.global.complaints.created -= 1
                monthData.global.complaints.created -= 1
                dayData.global.complaints.created -= 1
                
                await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc(this.complaint.createdDate.year.toString()).set(yearData);
            },
            isIncludedInFilesToDelete(file,c)
            {
                let included = false;
                for(let i=0; i<this.filesMarkedToDelete[c.id].length;i++)
                {
                    if(this.filesMarkedToDelete[c.id][i].file === file)
                    {
                        included = true;
                    }
                }
                return included;
            },
            unmarkFileFromDelete(file,c)
            {
                for(let i=0; i<this.filesMarkedToDelete[c.id].length;i++)
                {
                    if(this.filesMarkedToDelete[c.id][i].file === file)
                    {
                        this.filesMarkedToDelete[c.id].splice(i,1);
                    }
                }
            },
            markFileAsToDelete(file,type,c,fileIndexInCase)
            {
                let fileToDelete = {
                    file: file,
                    type: type,
                    c: c,
                    indx: fileIndexInCase
                };
                this.filesMarkedToDelete[c.id].push(fileToDelete);
            },
            correctDataWhenAcceptanceChanged(c, cIndex)
            {
              if(c.acceptance === 'declined')
              {
                c.action = 'No action';
                c.reason = 'Other';
                c.completed = true;
                c.item =  this.beforeEditComplaint.cases[cIndex].item
              }
              if(c.acceptance === 'accepted')
              {
                c.reason = 'Other';
              }
            },
            async updateComplaintCostFromProduct(c)
            {
                try{
                    const prodResult = await c.item.ref.get();
                    if(prodResult.exists)
                    {
                        c.item.complaintCostAtAddingTime = prodResult.data().pricesAndCosts.complaintCost;
                        c.item.attributesAtAddingTime = prodResult.data().attribs;
                        c.item.currencyAtAddingTime = prodResult.data().pricesAndCosts.currency;
                        c.item.ean = prodResult.data().ean;
                        c.item.id = prodResult.data().id;
                        c.item.imageAtAddingTime = prodResult.data().image.url;
                        c.item.model = prodResult.data().model;
                        c.item.name = prodResult.data().name;
                        c.item.productionCostAtAddingTime = prodResult.data().pricesAndCosts.productionCost;
                        c.item.sellPriceAtAddingTime = prodResult.data().pricesAndCosts.sellPrice;
                        c.item.sku = prodResult.data().sku;
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Price updated!",
                            subheader: `Complaint cost updated from current product state.`,
                            success: true
                        });
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Oops! Something went wrong!",
                            subheader: `Product might not exist. Check it in product manager.`,
                            success: false
                        });
                    }

                }
                catch(error)
                {
                    console.log(error);
                }
            },
            async getActions()
            {
              // Receive Actions
              const result = await db.collection("OptionValues").doc("ComplaintActions").get();
              if(result.exists)
              {
                this.actionsList = result.data().values;
              }
              const resultPrefByClient = await db.collection("OptionValues").doc("ExpectationsValues").get();
              if(resultPrefByClient.exists)
              {
                this.actionsPreferredByClientList = resultPrefByClient.data().values;
              }
            },
            async getReasons()
            {
              // Accepted
              const result = await db.collection("OptionValues").doc("ComplaintReasons").get();
              if(result.exists)
              {
                this.reasonsList = result.data().values;
              }
              // Denied
              const result2 = await db.collection("OptionValues").doc("ComplaintDenyReasons").get();
              if(result2.exists)
              {
                this.reasonsDenyList = result2.data().values;
              }
            },
            async deleteCase()
            {
                try{
                    if(this.caseIndxToDelete === -1) return false;
                    const pickedCase = this.complaint.cases[this.caseIndxToDelete];
                    for(let y=0; y<pickedCase.files.images.length;y++)
                    {
                        await storage.ref().child(pickedCase.files.images[y].path).delete();
                    }
                    for(let y=0; y<pickedCase.files.pdfs.length;y++)
                    {
                        await storage.ref().child(pickedCase.files.pdfs[y].path).delete();
                    }
                    await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaint.id.toString()).update({
                        cases: firebase.firestore.FieldValue.arrayRemove(pickedCase)
                    });
                    
                    await this.deleteCaseFromAnalitycs(this.caseIndxToDelete)

                    // Make complaint in progress when 0 cases
                    if(this.complaint.cases.length - 1 === 0 && this.complaint.complaint !== false)
                    {

                        await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaint.id.toString()).update({
                            'completed': false,
                            'portalData.completeDate': null
                        });
                    }
                    this.getComplaintById();
                    this.$store.state.userData.system.ref.collection("Events").add(
                    {
                        user: {
                            name: this.$store.state.user.displayName,
                            photo: this.$store.state.user.photoURL
                        },
                        creationDate: moment().toDate(),
                        eventName: `Deleted case in complaint ${this.complaint.id}.`
                    })
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Case has been deleted!",
                        subheader: ``,
                        success: true
                    });

                }
                catch(error)
                {
                    console.log(error);
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Oops! Something went wrong!",
                        subheader: `Cannot delete this case.`,
                        success: false
                    });
                }

            },
            askForCasesDelete(indx)
            {
                this.caseIndxToDelete = indx;
                this.editModals.askForCasesDelete = true;
            },
            allowEditMode(indx)
            {
                this.casesEditMode[indx] = !this.casesEditMode[indx];
                this.caseInEdit = this.complaint.cases[indx];
                if(!this.casesEditMode[indx])
                {
                    this.caseInEdit = null;
                    this.$refs[`pdfUpldr${indx}`][0].value = '';
                    this.$refs[`imgUpldr${indx}`][0].value = '';
                    this.pdfHolder[this.complaint.cases[indx].id.toString()] = [];
                    this.imgHolder[this.complaint.cases[indx].id.toString()] = [];
                    this.complaint.cases[indx] = {... this.casesBackup[indx]}
                    this.complaint.cases[indx].item.complaintCostAtAddingTime = this.complaintCostsBackup[this.complaint.cases[indx].id.toString()]
                }
            },
            async reloadClient()
            {
                try{
                    if(this.complaint.client.id.length !== 0)
                    {
                        const clientDataRes = await this.$store.state.userData.system.ref.collection("Clients").doc(this.complaint.client.id.toString()).get();
                        if(clientDataRes.exists)
                        {
                            const client = clientDataRes.data();
                            await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaint.id.toString()).update(
                                {
                                    'client.address': client.address,
                                    'client.city': client.city,
                                    'client.country': client.country,
                                    'client.description': client.description,
                                    'client.email': client.email,
                                    'client.name': client.name,
                                    'client.phoneNum': client.phoneNum,
                                    'client.postCode': client.postCode,
                                    'client.type': client.type
                                }
                            )
                            this.getComplaintById();
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Updated with success!",
                                subheader: `Client data updated from current database state.`,
                                success: true
                            });
                        }
                    }
                    else
                    {
                        throw "Client doesn't exsist.";
                    }
                }
                catch(error)
                {
                    console.log(error);
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Oops! Cannot run update action.",
                        subheader: `Client data might not exist now.`,
                        success: false
                    });
                }

            },
            daysRemaining(dateStr) {
                var eventdate = moment(dateStr);
                var todaysdate = moment();
                return eventdate.diff(todaysdate, 'days');
            },
            async elasticDocUpdate()
            {
                try{
                    await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/update/doc",{
                        system: this.$store.state.userData.system.id,
                        id: this.complaint.elasticSearch.docId,
                        type: "complaints",
                        doc: {
                            id: this.complaint.id.toString(),
                            clientName: this.complaint.client.name,
                            email: this.complaint.client.email,
                            phone: this.complaint.client.phoneNum,
                            externalOrderNum: this.complaint.externalIds.orderNum,
                            externalClientNum: this.complaint.externalIds.clientNum,
                            externalInvoiceNum: this.complaint.externalIds.invoiceNum
                        }
                    })
                }
                catch(error)
                {
                    console.log(error);
                }
            },
            async deleteComplaint()
            {
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Deleting complaint..",
                    subheader: `Do not close this tab.`,
                });
                try {
                    await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/delete",{
                    system: this.$store.state.userData.system.id,
                    docsToDelete: [this.complaint.elasticSearch.docId],
                    type: 'complaints'
                    });
                    // Delete all files
                    for(let i=0;i < this.complaint.cases.length;i++)
                    {
                        let c = this.complaint.cases[i];
                        for(let y=0; y<c.files.images.length;y++)
                        {
                            await storage.ref().child(c.files.images[y].path).delete();
                        }
                        for(let y=0; y<c.files.pdfs.length;y++)
                        {
                            await storage.ref().child(c.files.pdfs[y].path).delete();
                        }
                    }
                    // Delete complaint from client
                    await this.$store.state.userData.system.ref.collection("Clients").doc(this.complaint.client.id.toString()).update({
                        'complaints': firebase.firestore.FieldValue.arrayRemove({id: this.complaint.id.toString()})
                        }
                    )
                    
                    // delete complaint from analytics
                    await this.deleteComplaintFromAnalitycs()

                    // delete complaint
                    await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaint.id.toString()).delete();
                    await this.$store.state.userData.system.ref.collection("Chats").doc(this.complaint.id.toString()).delete();

                    this.$store.state.userData.system.ref.collection("Events").add(
                            {
                            user: {
                                name: this.$store.state.user.displayName,
                                photo: this.$store.state.user.photoURL
                            },
                            creationDate: moment().toDate(),
                            eventName: `Deleted complaint ${this.complaint.id}.`
                    })
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Complaint has been deleted!",
                            subheader: ``,
                            success: true
                        });
                    this.$router.push("/dashboard/complaints/list");
                }
                catch(error)
                {
                    console.log(error);
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Oops! Something went wrong!",
                        subheader: `Cannot delete this complaint.`,
                        success: false
                    });
                    this.$router.push("/dashboard/complaints/list");
                }

                
            },
            openAttributeModal(item)
            {
                this.modal = {
                    open: true,
                    selectedCaseItem: item
                }
            },
            closeAttributeModal()
            {
                this.modal = {
                    open: false,
                    selectedCaseItem: null
                }
            },
            getModificationDateString(t)
            {
                return moment(new Date(t.seconds * 1000 + t.nanoseconds/1000000)).format("DD.MM.YYYY")
            },
            // Switching Tabs
            setTab($ev,tab)
            {
                for(let i=0; i<this.tabs.length; i++)
                {
                    this.tabs[i].current = false;
                }
                tab.current = true;
                this.currentContainerNum = tab.container;
            },
            // Address in one string
            fullAddressString()
            {
                let arr = []
                let cityArr = [];
                if(this.complaint.client.address.length>0) arr.push(this.complaint.client.address);
                if(this.complaint.client.postCode.length>0) cityArr.push(this.complaint.client.postCode);
                if(this.complaint.client.city.length>0) cityArr.push(this.complaint.client.city);
                if(cityArr.length>0) arr.push(cityArr.toString().replace(',',' '));
                if(this.complaint.client.country.length>0) arr.push(' ' + this.complaint.client.country);
                return arr.toString().replace(",",", ").length === 0 ? "----" : arr.toString().replace(",",", ");
            },
            // Downloading complaint
            async getComplaintById()
            {
                this.caseInEdit = null;
                this.filesMarkedToDelete = {};
                this.casesBackup = [];
                this.complaintCostsBackup = {};
                this.casesEditMode = [];
                if(!this.$route.query.id) this.$router.push("/dashboard/complaints/list");
                const getResult = await this.$store.state.userData.system.ref.collection("Complaints").doc(this.$route.query.id).get();
                if(!getResult.exists) this.$router.push("/dashboard/complaints/list");
                this.complaint = getResult.data();
                this.beforeEditComplaint = getResult.data();
                for(let i=0; i < this.complaint.cases.length; i++)
                {
                    this.casesEditMode.push(false);
                    this.casesBackup.push({...this.complaint.cases[i]});
                    this.complaintCostsBackup[this.complaint.cases[i].id.toString()] = this.complaint.cases[i].item.complaintCostAtAddingTime;
                    this.filesMarkedToDelete[this.complaint.cases[i].id.toString()] = [];
                    this.imgHolder[this.complaint.cases[i].id.toString()] = [];
                    this.pdfHolder[this.complaint.cases[i].id.toString()] = [];
                }
                document.title = `Manage complaint ${this.complaint.id} - Complaia`;
            },
            // Translate attendant id to readable name
            getAttendantById(id)
            {
                let attendantName = "DELETED";
                for(let i=0; i<this.$store.state.systemData.users.length;i++)
                {
                    if(this.$store.state.systemData.users[i].id.toString() === id.toString())
                    {
                        attendantName = this.$store.state.systemData.users[i].displayName;
                    }
                }
                return attendantName
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>