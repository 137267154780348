<template>
  <TransitionRoot as="template" v-if="this.complaint !== undefined" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle min-w-full sm:min-w-2xl max-w-3xl sm:max-h-screen9/10 sm:p-6">
            <!-- <div v-if="variant.attribs.list.length > 0">{{variant.attribs.list}}</div> -->

            <!-- <div @click.prevent="emitClose()">Close</div> -->
            <div class="bg-white border-b border-gray-100 pt-5 sm:px-6">
                <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div class="pl-4 sm:pl-0 pb-4">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Notification center</h3>
                    <p class="mt-1 text-sm text-gray-500">Notify user of changes in complaint by email message.</p>
                </div>
                </div>
            </div>

            <div class="overflow-hidden bg-white shadow sm:rounded-b-md">
                <ul role="list" class="divide-y divide-gray-200">
                <li>
                    <div class="block">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                        <p class="mt-1 truncate text-sm font-medium text-purple-600">Complaint has been created</p>
                        <div class="ml-2 flex flex-shrink-0">
                            <button :disabled="this.lock.creation" @click.prevent="sendNotification('creation')" :class="{'transition ease-in-out duration-300 hidden sm:inline-flex rounded-md cursor-pointer px-2 py-1 text-xs font-semibold leading-5': true, 'bg-purple-500 text-white': this.complaint.notifications === undefined || this.complaint.notifications.complaintCreation === null, 'hover:bg-purple-700': (this.complaint.notifications === undefined || this.complaint.notifications.complaintCreation === null) && !this.lock.creation, 'bg-green-500 text-white pointer-events-none cursor-default': this.complaint.notifications !== undefined && this.complaint.notifications.complaintCreation !== null, 'opacity-50 cursor-not-allowed': (this.complaint.notifications === undefined || this.complaint.notifications.complaintCreation === null) && this.lock.creation}"><CheckIcon v-if="this.complaint.notifications !== undefined && this.complaint.notifications.complaintCreation !== null" class="mr-1.5 my-auto h-4 w-4 flex-shrink-0" aria-hidden="true" /> {{this.complaint.notifications !== undefined && this.complaint.notifications.complaintCreation !== null ? "Notified" : this.lock.creation ? "Sending..." : "Send notification"}}</button>
                            <!-- <button :disabled="this.lock.attendant" @click.prevent="sendNotification('attendant')" :class="{'transition ease-in-out duration-300 hidden sm:inline-flex rounded-md cursor-pointer px-2 py-1 text-xs font-semibold leading-5': true, 'bg-purple-500 text-white': this.complaint.notifications === undefined || this.complaint.notifications.attendantInformation === null, 'hover:bg-purple-700': (this.complaint.notifications === undefined || this.complaint.notifications.attendantInformation === null) && !this.lock.attendant,'bg-green-500 text-white pointer-events-none cursor-default': this.complaint.notifications !== undefined && this.complaint.notifications.attendantInformation !== null, 'opacity-50 cursor-not-allowed': (this.complaint.notifications === undefined || this.complaint.notifications.attendantInformation === null) && this.lock.attendant}"><CheckIcon v-if="this.complaint.notifications !== undefined && this.complaint.notifications.attendantInformation !== null" class="mr-1.5 my-auto h-4 w-4 flex-shrink-0" aria-hidden="true" /> {{this.complaint.notifications !== undefined && this.complaint.notifications.attendantInformation !== null ? "Notified" : this.lock.attendant ? "Sending..." : "Send notification"}}</button> -->
                        </div>
                        </div>
                        <div class="sm:flex sm:justify-between">
                        <div class="mb-1 sm:flex">
                            <p class="flex items-center text-sm text-gray-500">
                                Inform client about complaint creation.
                            </p>
                        </div>
                        <div class="mt-2 mb-1 flex flex-shrink-0">
                            <button :disabled="this.lock.creation" @click.prevent="sendNotification('creation')" :class="{'transition ease-in-out duration-300 sm:hidden inline-flex rounded-md cursor-pointer px-2 py-1 text-xs font-semibold leading-5': true, 'bg-purple-500 text-white': this.complaint.notifications === undefined || this.complaint.notifications.complaintCreation === null, 'hover:bg-purple-700': (this.complaint.notifications === undefined || this.complaint.notifications.complaintCreation === null) && !this.lock.creation, 'bg-green-500 text-white pointer-events-none cursor-default': this.complaint.notifications !== undefined && this.complaint.notifications.complaintCreation !== null, 'opacity-50 cursor-not-allowed': (this.complaint.notifications === undefined || this.complaint.notifications.complaintCreation === null) && this.lock.creation}"><CheckIcon v-if="this.complaint.notifications !== undefined && this.complaint.notifications.complaintCreation !== null" class="mr-1.5 my-auto h-4 w-4 flex-shrink-0" aria-hidden="true" /> {{this.complaint.notifications !== undefined && this.complaint.notifications.complaintCreation !== null ? "Notified" : this.lock.creation ? "Sending..." : "Send notification"}}</button>
                        </div>
                        <div class="pt-1 flex items-center text-sm text-gray-500 sm:mt-0">
                            <CalendarIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <p v-if="this.complaint.notifications === undefined || this.complaint.notifications.complaintCreation === null">
                            Notification not sent yet
                            </p>
                            <p v-else>
                               Notified at {{this.getModificationDateString(this.complaint.notifications.complaintCreation)}}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </li>
                <li>
                    <div class="block">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                        <p class="truncate mt-1 text-sm font-medium text-purple-600">Complaint has been updated</p>
                        <div class="ml-2 flex flex-shrink-0">
                            <button :disabled="this.lock.update" @click.prevent="sendNotification('update')" :class="{'transition ease-in-out duration-300 hidden sm:inline-flex rounded-md cursor-pointer px-2 py-1 text-xs font-semibold leading-5': true, 'bg-purple-500 text-white': this.complaint.notifications === undefined || this.complaint.notifications.complaintUpdate === null || this.complaint.notifications.complaintUpdate !== null && !this.cooldownUpdateNotification, 'hover:bg-purple-700': (this.complaint.notifications === undefined || this.complaint.notifications.complaintUpdate === null || this.complaint.notifications.complaintUpdate !== null && !this.cooldownUpdateNotification) && !this.lock.update, 'bg-gray-400 opacity-80 text-white pointer-events-none cursor-default': this.complaint.notifications !== undefined && this.complaint.notifications.complaintUpdate !== null && this.cooldownUpdateNotification, 'opacity-50 cursor-not-allowed': (this.complaint.notifications === undefined || this.complaint.notifications.complaintUpdate === null || this.complaint.notifications.complaintUpdate !== null && !this.cooldownUpdateNotification) && this.lock.update}">{{this.complaint.notifications !== undefined && this.complaint.notifications.complaintUpdate !== null && this.cooldownUpdateNotification ? "Next notify possible at " + this.nextPossibleResend() : this.lock.update ? "Sending..." : "Send notification"}}</button>
                        </div>
                        </div>
                        <div class="sm:flex sm:justify-between">
                        <div class="sm:flex">
                            <p class="mb-1 flex items-center text-sm text-gray-500">
                                Inform client about update in complaint.
                            </p>
                        </div>
                        <div class="mt-2 mb-1 flex flex-shrink-0">
                            <button :disabled="this.lock.update" @click.prevent="sendNotification('update')" :class="{'transition ease-in-out duration-300 sm:hidden inline-flex rounded-md cursor-pointer px-2 py-1 text-xs font-semibold leading-5': true, 'bg-purple-500 text-white': this.complaint.notifications === undefined || this.complaint.notifications.complaintUpdate === null || this.complaint.notifications.complaintUpdate !== null && !this.cooldownUpdateNotification, 'hover:bg-purple-700': (this.complaint.notifications === undefined || this.complaint.notifications.complaintUpdate === null || this.complaint.notifications.complaintUpdate !== null && !this.cooldownUpdateNotification) && !this.lock.update, 'bg-gray-400 opacity-80 text-white pointer-events-none cursor-default': this.complaint.notifications !== undefined && this.complaint.notifications.complaintUpdate !== null && this.cooldownUpdateNotification, 'opacity-50 cursor-not-allowed': (this.complaint.notifications === undefined || this.complaint.notifications.complaintUpdate === null || this.complaint.notifications.complaintUpdate !== null && !this.cooldownUpdateNotification) && this.lock.update}">{{this.complaint.notifications !== undefined && this.complaint.notifications.complaintUpdate !== null && this.cooldownUpdateNotification ? "Next notify possible at " + this.nextPossibleResend() : this.lock.update ? "Sending..." : "Send notification"}}</button>
                        </div>
                        <div class="pt-1 flex items-center text-sm text-gray-500 sm:mt-0">
                            <CalendarIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <p v-if="this.complaint.notifications === undefined || this.complaint.notifications.complaintUpdate === null">
                            Notification not sent yet
                            </p>
                            <p v-else>
                                Last notification: {{this.getModificationDateString(this.complaint.notifications.complaintUpdate)}}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </li>
                <li>
                    <div class="block">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                        <p class="truncate mt-1 text-sm font-medium text-purple-600">Information of attendant</p>
                        <div class="ml-2 flex flex-shrink-0">
                            <button :disabled="this.lock.attendant" @click.prevent="sendNotification('attendant')" :class="{'transition ease-in-out duration-300 hidden sm:inline-flex rounded-md cursor-pointer px-2 py-1 text-xs font-semibold leading-5': true, 'bg-purple-500 text-white': this.complaint.notifications === undefined || this.complaint.notifications.attendantInformation === null, 'hover:bg-purple-700': (this.complaint.notifications === undefined || this.complaint.notifications.attendantInformation === null) && !this.lock.attendant,'bg-green-500 text-white pointer-events-none cursor-default': this.complaint.notifications !== undefined && this.complaint.notifications.attendantInformation !== null, 'opacity-50 cursor-not-allowed': (this.complaint.notifications === undefined || this.complaint.notifications.attendantInformation === null) && this.lock.attendant}"><CheckIcon v-if="this.complaint.notifications !== undefined && this.complaint.notifications.attendantInformation !== null" class="mr-1.5 my-auto h-4 w-4 flex-shrink-0" aria-hidden="true" /> {{this.complaint.notifications !== undefined && this.complaint.notifications.attendantInformation !== null ? "Notified" : this.lock.attendant ? "Sending..." : "Send notification"}}</button>
                        </div>
                        </div>
                        <div class="sm:flex sm:justify-between">
                        <div class="sm:flex">
                            <p class="flex mb-1 items-center text-sm text-gray-500">
                                Inform your client about assigned attendant.
                            </p>
                        </div>
                        <div class="mt-2 mb-1 flex flex-shrink-0">
                            <button :disabled="this.lock.attendant" @click.prevent="sendNotification('attendant')" :class="{'transition ease-in-out duration-300 sm:hidden inline-flex rounded-md cursor-pointer px-2 py-1 text-xs font-semibold leading-5': true, 'bg-purple-500 text-white': this.complaint.notifications === undefined || this.complaint.notifications.attendantInformation === null, 'hover:bg-purple-700': (this.complaint.notifications === undefined || this.complaint.notifications.attendantInformation === null) && !this.lock.attendant,'bg-green-500 text-white pointer-events-none cursor-default': this.complaint.notifications !== undefined && this.complaint.notifications.attendantInformation !== null, 'opacity-50 cursor-not-allowed': (this.complaint.notifications === undefined || this.complaint.notifications.attendantInformation === null) && this.lock.attendant}"><CheckIcon v-if="this.complaint.notifications !== undefined && this.complaint.notifications.attendantInformation !== null" class="mr-1.5 my-auto h-4 w-4 flex-shrink-0" aria-hidden="true" /> {{this.complaint.notifications !== undefined && this.complaint.notifications.attendantInformation !== null ? "Notified" : this.lock.attendant ? "Sending..." : "Send notification"}}</button>
                        </div>
                        <div class="pt-1 flex items-center text-sm text-gray-500 sm:mt-0">
                            <CalendarIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <p v-if="this.complaint.notifications === undefined || this.complaint.notifications.attendantInformation === null">
                                Notification not sent yet
                            </p>
                            <p v-else>
                                Notified at {{this.getModificationDateString(this.complaint.notifications.attendantInformation)}}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </li>
                </ul>
            </div>
            <div class="mt-4 flex justify-end flex-shrink-0">
                <button @click.prevent="emitClose()" class="transition ease-in-out duration-300 inline-flex rounded-md cursor-pointer bg-purple-500 hover:bg-purple-700 px-4 py-2 text-sm font-semibold leading-5 text-white">Close notification center</button>
            </div>

          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import moment from 'moment';
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { 
  CalendarIcon
  } from '@heroicons/vue/outline'
import { ref } from 'vue'
import { CheckIcon } from '@heroicons/vue/solid'
export default {
    data()
        {
        return {
            cooldownUpdateNotification: false,
            lock:{
                creation: false,
                update: false,
                attendant: false
            }
        }
    },
    components: {
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
        CalendarIcon,
        CheckIcon
    },
    props: ['complaint','useCheckTimeout'],
    methods: {
        nextPossibleResend(){
            setTimeout(()=>{
                this.nextPossibleResend()
            },1000)
            return moment(new Date((this.complaint.notifications.complaintUpdate + 3600) * 1000)).format("LT")
        },
        checkForTimeout(){
            if(moment().unix() > this.complaint.notifications.complaintUpdate + 3600){
                this.lock.update = false
                this.cooldownUpdateNotification = false
            }else{
                setTimeout(()=>{
                    this.checkForTimeout()
                },(this.complaint.notifications.complaintUpdate + 3600 - parseInt(moment().unix())) * 1000)
                this.cooldownUpdateNotification = true
                this.lock.update = true
            }
        },
        buttonLocker(type){
            if(type === 'creation'){
                this.lock.creation = true
            }
            if(type === 'update'){
                this.lock.update = true
            }
            if(type === 'attendant'){
                this.lock.attendant = true
            }
        },
        getModificationDateString(t)
            {
                return moment(new Date(t * 1000)).format("lll")
            },
        sendNotification(type){
            this.buttonLocker(type)
            this.$emit("updateNotifications", type)
            // setTimeout(() => {
            //     this.checkForTimeout()
            // }, 3000);
            if(type === 'update'){
            this.cooldownUpdateNotification = true
            }
        },
        emitClose()
        {
            this.$emit("closeModal");
        },
        watchForComplaint(){
                if(this.complaint === undefined){
                    this.watchForComplaint
                }else{
                    if(this.complaint.notifications !== undefined){
                        if(this.complaint.notifications.complaintUpdate !== null){
                            this.checkForTimeout()
                        }
                        if(this.complaint.notifications.complaintCreation !== null){
                            this.lock.creation = true
                        }
                        if(this.complaint.notifications.attendantInformation !== null){
                            this.lock.attendant = true
                        }
                    }
                }
            }
    },
    computed:
    {

    },
    mounted() {
        this.watchForComplaint()
    },
    setup() {
        const open = ref(true)
        return {
        open,
        }
    },
}
</script>
