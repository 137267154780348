<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10"  @close="closeModal()" :key="reload">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden flex justify-center">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-screen">
                <Search :type="'products'" :show="showSearchProduct" @setThing="setProduct" @toggle="toggleSearchProducts"></Search>
                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
              <div class="flex justify-center">
              <div class="relative flex-1 px-4 sm:px-6 max-w-5xl ">
              <div>
                  <div class="flex items-start justify-between">
                  <DialogTitle class="text-lg font-medium text-gray-900">Create new case <span class="text-xs text-yellow-700" v-show="!newCase.completed">(In progress)</span><span class="text-xs text-green-700" v-show="newCase.completed">(Completed)</span></DialogTitle>
                  <div class="ml-3 flex h-7 items-center">
                      <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" @click="closeModal">
                      <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                  </div>
                  </div>
              </div>
              <form>
              <div class="md:col-span-1">
                <label  class="block text-sm font-medium text-gray-700 mt-2">Selected product*</label>
                <div class="mt-4" v-if="newCase.item.id.length === 0">
                  <button type="button" @click="toggleSearchProducts(0)" class="relative block w-full border-2 border-purple-200 border-dashed rounded-lg p-12 text-center hover:border-purple-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    <div class="flex justify-center"><SearchIcon class="h-6 w-6 text-purple-300"></SearchIcon></div>
                    <span class="mt-2 block text-sm text-purple-400" > Click here to select product.. </span>
                  </button>
                </div>
                <div v-if="newCase.item.id.length > 0">
                  <div>
                    <div class="flex items-center">
                        <div class="h-28 w-28 flex-shrink-0 border-2 border-gray-100 mt-2 select-none">
                          <img class="h-28 w-28 rounded-md object-center object-scale-down" :src="newCase.item.imageAtAddingTime.length>0 ? newCase.item.imageAtAddingTime : `https://avatars.dicebear.com/api/initials/${encodeURIComponent(`Product${newCase.item.id}`)}.svg`"  />
                        </div>
                        <div class="ml-2 select-none">
                          <div>{{newCase.item.name}}</div>
                          <div class="text-xs text-gray-500">Complaint cost: {{newCase.acceptance === 'accepted' ? newCase.item.complaintCostAtAddingTime.toFixed(2) : '0.00'}} {{newCase.item.currencyAtAddingTime}}</div>
                          <button @click="toggleSearchProducts(indx)" type="button" class="mt-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Change</button>
                        </div>
                    </div>
                  </div>
                  <div class="mt-2">
                    <div class="text-xs font-medium text-gray-700 select-none">Attributes</div>
                    <div class="grid grid-cols-2 gap-x-4 gap-y-2 mt-1">
                      <span v-if="newCase.item.attributesAtAddingTime.length === 0" class="text-xs text-gray-400 select-none">No attributes</span>
                      <span v-for="atr in newCase.item.attributesAtAddingTime" :key="atr.id" class="select-none inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
                        <svg class="mr-1.5 h-2 w-2 text-purple-400" fill="currentColor" viewBox="0 0 8 8">
                          <circle cx="4" cy="4" r="3" />
                        </svg>
                        {{atr.parent.name}}: {{atr.name}}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <p class="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p> -->


              </div>


                            <div class="grid grid-cols-6 gap-6">
                                <div class="col-span-6 sm:col-span-6 select-none">
                                    <!-- OLD VERSION -->
                                    <!-- <label class="block text-sm font-medium mt-2 text-gray-700">Acceptance*</label>
                                    <select v-model="newCase.acceptance" @change="correctDataWhenAcceptanceChanged(newCase)" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                                        <option value="accepted">Accepted</option>
                                        <option value="declined">Declined</option>
                                    </select> -->
                                    <Listbox as="div" v-model="newCase.acceptance" class="mt-2">
                                      <ListboxLabel class="block text-sm font-medium text-gray-700"> Acceptance* </ListboxLabel>
                                      <div class="mt-1 relative">
                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                          <span class="flex items-center truncate">{{`${newCase.acceptance[0].toUpperCase()}${newCase.acceptance.slice(1)}`}}</span>
                                          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                          </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                          <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                            <ListboxOption @click.prevent="correctDataWhenAcceptanceChanged(newCase)" as="template" value="accepted" v-slot="{ active, selected }">
                                              <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Accepted</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              </li>
                                            </ListboxOption>
                                            <ListboxOption @click.prevent="correctDataWhenAcceptanceChanged(newCase)" as="template" value="declined" v-slot="{ active, selected }">
                                              <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Declined</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              </li>
                                            </ListboxOption>
                                          </ListboxOptions>
                                        </transition>
                                      </div>
                                    </Listbox>
                                </div>
                                <div :class="{'col-span-6 select-none': true, 'sm:col-span-3': newCase.acceptance === 'accepted', 'sm:col-span-6': newCase.acceptance === 'declined'}">
                                    <!-- OLD VERSION -->
                                    <!-- <label class="block text-sm font-medium text-gray-700">Reason*</label>
                                    <select v-model="newCase.reason" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                                        <option v-for="(reason,rsnIndx) in newCase.acceptance === 'accepted' ? reasonsList : reasonsDenyList" :key="rsnIndx" :value="reason.name">{{reason.name}}</option>
                                    </select> -->
                                    <Listbox as="div" v-model="newCase.reason">
                                      <ListboxLabel class="block text-sm font-medium text-gray-700"> Reason* </ListboxLabel>
                                      <div class="mt-1 relative">
                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                          <span class="flex items-center truncate">{{newCase.reason}}</span>
                                          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                          </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                          <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                            <ListboxOption as="template" v-for="(reason,rsnIndx) in newCase.acceptance === 'accepted' ? reasonsList : reasonsDenyList" :key="rsnIndx" :value="reason.name" v-slot="{ active, selected }">
                                              <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{reason.name}}</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              </li>
                                            </ListboxOption>
                                          </ListboxOptions>
                                        </transition>
                                      </div>
                                    </Listbox>
                                </div>
                                <div class="col-span-6 sm:col-span-3 select-none" v-if="newCase.acceptance === 'accepted'">
                                    <!-- OLD VERSION -->
                                    <!-- <label  class="block text-sm font-medium text-gray-700">Action*</label>
                                    <select v-model="newCase.action" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                                        <option v-for="(action,actIndx) in actionsList" :key="actIndx" :value="action.name">{{action.name}}</option>
                                    </select> -->
                                    <Listbox as="div" v-model="newCase.action">
                                      <ListboxLabel class="block text-sm font-medium text-gray-700"> Action* </ListboxLabel>
                                      <div class="mt-1 relative">
                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                          <span class="flex items-center truncate">{{newCase.action}}</span>
                                          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                          </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                          <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                            <ListboxOption as="template" v-for="(action,actIndx) in actionsList" :key="actIndx" :value="action.name" v-slot="{ active, selected }">
                                              <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{action.name}}</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              </li>
                                            </ListboxOption>
                                          </ListboxOptions>
                                        </transition>
                                      </div>
                                    </Listbox>
                                </div>
                                <div class="col-span-6 select-none">
                                    <!-- OLD VERSION -->
                                    <!-- <label  class="block text-sm font-medium text-gray-700">Action*</label>
                                    <select v-model="newCase.action" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                                        <option v-for="(action,actIndx) in actionsList" :key="actIndx" :value="action.name">{{action.name}}</option>
                                    </select> -->
                                    <Listbox as="div" v-model="newCase.preferredActionByClient">
                                      <ListboxLabel class="block text-sm font-medium text-gray-700"> Action preferred by client </ListboxLabel>
                                      <div class="mt-1 relative">
                                        <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                          <span class="flex items-center truncate">{{newCase.preferredActionByClient}}</span>
                                          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                          </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                          <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                            <ListboxOption as="template" v-for="(action,actIndx) in actionsPreferredByClientList" :key="actIndx" :value="action.name" v-slot="{ active, selected }">
                                              <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{action.name}}</span>

                                                <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              </li>
                                            </ListboxOption>
                                          </ListboxOptions>
                                        </transition>
                                      </div>
                                    </Listbox>
                                </div>
                            </div>
                            <div class="mt-2">
                                <label for="comment" class="block text-sm font-medium text-gray-700">Description</label>
                                <div class="mt-1">
                                <textarea rows="3" v-model="newCase.description" class="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm border-gray-300 rounded-md" />
                                </div>
                            </div>

                            <div class="relative mt-4 mb-4 opacity-60 select-none">
                                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                <div class="w-full border-t border-gray-300" />
                                </div>
                                <div class="relative flex justify-start">
                                <span class="bg-white pr-2 text-sm text-gray-500">Files</span>
                                </div>
                            </div>

                            <div class="mt-4">
                                <label for="comment" class="block text-sm font-medium text-gray-700 mb-2 select-none">Images</label>
                                <div class="flex items-center" v-if="imgHolder[newCase.id].length === 0">
                                    <PhotographIcon class="h-4 w-4 mr-1 opacity-40" aria-hidden="true" />
                                    <span class="text-xs opacity-40">Images not selected..</span>
                                </div>
                                <div v-for="(fl,flNdx) in imgHolder[newCase.id]" :key="flNdx" class="flex">
                                    <PhotographIcon class="h-4 w-4 mr-1 opacity-40" aria-hidden="true" />
                                    <span class="text-xs opacity-60">{{fl.file.name.substring(0,100)}}</span>
                                    <span class="text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer" @click.prevent="imgHolder[newCase.id].splice(flIndx,1)">Delete</span>
                                </div>
                            </div>

                            <div class="mt-2 select-none mb-2" v-if="evaluate(this.$store.state.userSubscription.items[0].price.product.metadata.pdfUpload)">
                                <label for="comment" class="block text-sm font-medium text-gray-700 mb-2 ">PDF</label>
                                <div class="flex items-center" v-if="pdfHolder[newCase.id].length === 0">
                                    <DocumentIcon class="h-4 w-4 mr-1 opacity-40" aria-hidden="true" />
                                    <span class="text-xs opacity-40">PDF file not selected..</span>
                                </div>
                                <div class="flex items-center" v-for="(pdf,pdfIndx) in pdfHolder[newCase.id]" :key="pdfIndx">
                                    <DocumentIcon class="h-4 w-4 mr-1 opacity-60" aria-hidden="true" />
                                    <span class="text-xs opacity-60">{{pdf.file.name.substring(0,100)}}</span>
                                    <span class="text-xs ml-4 text-purple-500 underline hover:text-purple-700 cursor-pointer" @click.prevent="pdfHolder[newCase.id].splice(pdfIndx,1)">Delete</span>
                                </div>
                            </div>

                            <div class="relative mt-4 mb-4 opacity-60 select-none">
                                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                <div class="w-full border-t border-gray-300" />
                                </div>
                                <div class="relative flex justify-start">
                                <span class="bg-white pr-2 text-sm text-gray-500">Manage</span>
                                </div>
                            </div>

                            
                            <div class="flex justify-end">
                                <div>
                                    <input type="file" accept="image/*" v-show="false" :ref="`imgUpldr0`" @change="addToImgHolder($event,newCase.id)">
                                    <input type="file" accept="application/pdf" v-show="false" :ref="`pdfUpldr0`" @change="addToPDFHolder($event,newCase.id)">
                                </div>
                                <div v-if="evaluate(this.$store.state.userSubscription.items[0].price.product.metadata.pdfUpload)">
                                    <button type="button" @click.prevent="triggerPDFUploaderClick(`pdfUpldr0`)" :class="{'mt-2 mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-blue-700 hover:bg-blue-200 bg-blue-100 focus:ring-blue-500': true, 'opacity-30' : false}"><DocumentIcon class="h-4 w-4 mr-1" aria-hidden="true" />Attach PDF</button>
                                </div>
                                <div>
                                    <button type="button" @click.prevent="triggerImgUploaderClick(`imgUpldr0`, this.imgQty - imgHolder[newCase.id].length)" :class="{'mt-2 mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 text-blue-700 hover:bg-blue-200 bg-blue-100 focus:ring-blue-500': true, 'opacity-30' : this.imgQty - imgHolder[newCase.id].length === 0}"><CameraIcon class="h-4 w-4 mr-1" aria-hidden="true" />Add image ({{this.imgQty - imgHolder[newCase.id].length}} left)</button>
                                </div>
                                <div>
                                    <button @click.prevent="newCase.completed = !newCase.completed" type="button" :class="{'mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500': !newCase.completed, 'mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500': newCase.completed}"><span v-show="newCase.completed">Mark as in progress</span><span v-show="!newCase.completed">Mark as completed</span></button>
                                </div>
                                <div>
                                     <button @click.prevent="addCaseToComplaint" :class="{'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-purple-600 hover:bg-purple-700  focus:ring-purple-500': true, 'opacity-100': this.newCase.item.ref !== null, 'opacity-40 pointer-events-none': this.newCase.item.ref === null}">Add case</button>
                                </div>

                                
                                
                                
                               
                            </div>

                        </form>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import Search from "@/components/SearchInComplaintForm.vue";
    import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import {PhotographIcon,DocumentIcon,SearchIcon,CameraIcon, XIcon as XMarkIcon} from '@heroicons/vue/outline';
    // import EmptyState from '../components/EmptyState.vue';
    import moment from 'moment';
    import generateApiKey from 'generate-api-key';
    // import axios from 'axios';
    import {db,storage, firebase} from "@/firebase/gfbconf.js"
    import { Listbox, ListboxButton, ListboxOption, ListboxOptions,ListboxLabel } from '@headlessui/vue'
    import { CheckIcon, SelectorIcon} from '@heroicons/vue/solid'
    export default {
        name: "External Ids Modal",
        data()
        {
            return {
                reload: 0,
                actionsPreferredByClientList: [],
                showSearchProduct: false,
                open: true,
                newCase: null,
                imgHolder: {},
                pdfHolder: {},
                selectedPriority: null,
                priorityList: [],
                attendants: [],
                selectedAttendant: null,
                reasonsList: [],
                reasonsDenyList: [],
                actionsList: [],
                // Img upload settings
                perImgSizeMB: 4,
                imgQty: 4,
                // Pdf uplaod settings
                perPDFSizeMB: 2,
            }
        },
        props: ['complaintData'],
        components: {Listbox, ListboxButton,ListboxLabel, ListboxOption, ListboxOptions, CheckIcon, SelectorIcon, Search,PhotographIcon,DocumentIcon,CameraIcon,SearchIcon,Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,XMarkIcon},
        created()
        {
            this.checkPlan();
            this.prepareNewCase();
            this.getPriority();
            this.getAttendants();
            this.getReasons();
            this.getActions();
        },
        methods:
        {
        evaluate(string) {
          return eval(string)
        },
          checkPlan() {
            if(this.$store.state.userSubscription.items[0].price.product.metadata.planName === "starter") {
              this.perImgSizeMB = parseInt(this.$store.state.userSubscription.items[0].price.product.metadata.imageSizePerFileMb)
              this.imgQty = parseInt(this.$store.state.userSubscription.items[0].price.product.metadata.imagesPerComplaint)
            } else if(this.$store.state.userSubscription.items[0].price.product.metadata.planName === "standard") {
              this.perImgSizeMB = parseInt(this.$store.state.userSubscription.items[0].price.product.metadata.imageSizePerFileMb)
              this.imgQty = parseInt(this.$store.state.userSubscription.items[0].price.product.metadata.imagesPerComplaint)
            } else if(this.$store.state.userSubscription.items[0].price.product.metadata.planName === "pro") {
              this.perImgSizeMB = parseInt(this.$store.state.userSubscription.items[0].price.product.metadata.imageSizePerFileMb)
              this.imgQty = parseInt(this.$store.state.userSubscription.items[0].price.product.metadata.imagesPerComplaint)
            }
          },
            async fixCompletionOnComplaint()
            {
                try{
                    // Fix completion status
                    if(this.newCase.completed === false)
                    {
                        await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaintData.id.toString()).update({
                            "completed": false
                        })
                    }
                    else
                    {
                        let finished = 0;
                        for(let i=0; i< this.complaintData.otherCases.length;i++)
                        {
                            if(this.complaintData.otherCases[i].completed === true)
                            {
                                finished = finished+1;
                            }
                        }
                        if(finished === this.complaintData.otherCases.length)
                        {
                            await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaintData.id.toString()).update({
                                "completed": true
                            })  
                        }
                        else
                        {
                            await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaintData.id.toString()).update({
                                "completed": false
                            })                     
                        }
                    }
                }
                catch(error)
                {
                    console.log(error);
                }
            },
            async updateAnalitycs() {
                const refToYear = this.$store.state.userData.system.ref.collection('Analytics').doc(this.complaintData.complaint.createdDate.year.toString())

                // prepare analitycs objects
                let yearData = (await refToYear.get()).data()
                let monthData = yearData[moment(this.complaintData.complaint.createdDate.monthNum, 'MM').format('MMMM')]
                let dayData = monthData.days[this.complaintData.complaint.createdDate.dayNum]

                if(this.newCase.acceptance === "declined") {
                    dayData.global.complaints.declined += 1
                    monthData.global.complaints.declined += 1 
                    yearData.global.complaints.declined += 1
                }

                if(this.newCase.acceptance === "accepted") {

                  dayData.global.complaints.complaintsWorth += this.newCase.item.sellPriceAtAddingTime
                  monthData.global.complaints.complaintsWorth += this.newCase.item.sellPriceAtAddingTime
                  yearData.global.complaints.complaintsWorth += this.newCase.item.sellPriceAtAddingTime

                  dayData.global.products.forEach(item => {
                      if(item.id === this.newCase.item.id) {
                          item.costs.forEach(itemCost => {
                              if(itemCost.cost === this.newCase.item.complaintCostAtAddingTime) {
                                  itemCost.amount += 1
                              }
                          })
                      }
                  })

                  monthData.global.products.forEach(item => {
                      if(item.id === this.newCase.item.id) {
                          item.costs.forEach(itemCost => {
                              if(itemCost.cost === this.newCase.item.complaintCostAtAddingTime) {
                                  itemCost.amount += 1
                              }
                          })
                      }
                  })

                  yearData.global.products.forEach(item => {
                      if(item.id === this.newCase.item.id) {
                          item.costs.forEach(itemCost => {
                              if(itemCost.cost === this.newCase.item.complaintCostAtAddingTime) {
                                  itemCost.amount += 1
                              }
                          })
                      }
                  })

                }

                let complaintFinished = true;
                let newCaseFinished = true;

                this.complaintData.complaint.cases.forEach(item => {
                    if(!item.completed) {
                        complaintFinished = false
                    }
                })

                if(!this.newCase.completed) {
                  newCaseFinished = false
                }

                if(complaintFinished && !newCaseFinished) {
                    dayData.global.complaints.active += 1
                    monthData.global.complaints.active += 1 
                    yearData.global.complaints.active += 1
                    dayData.global.complaints.finished -= 1
                    monthData.global.complaints.finished -= 1 
                    yearData.global.complaints.finished -= 1
                }

                dayData.global.complaints.casesAmount += 1
                monthData.global.complaints.casesAmount += 1 
                yearData.global.complaints.casesAmount += 1

                await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc(this.complaintData.complaint.createdDate.year.toString()).set(yearData);
            },
            async addCaseToComplaint()
            {
                try{
                    // Check if data ok
                    if(this.complaintData.id === undefined || this.complaintData.id === null || this.complaintData.id.length === 0) return 0;
                    if(this.complaintData.status === undefined || this.complaintData.id === null) return 0;
                    if(this.newCase.item.ref === null)
                    {
                        throw "Cannot add case without product.";
                    }
                    this.$store.commit('setGlobalLoader',{
                        show: true,
                        head: "Adding case..",
                        subheader: `Do not close this tab.`,
                    });

                    // Fix complaint.completed
                    this.fixCompletionOnComplaint();

                    // Upload files
                    const filesArr = this.imgHolder[this.newCase.id];
                    const pdfArr = this.pdfHolder[this.newCase.id];
                    const storageRef = storage.ref();

                    for(let y=0; y < filesArr.length; y++)
                    {
                        const file = filesArr[y].file;
                        const filename = "img_" + this.newCase.id.toString() + y.toString();
                        const imageRef = storageRef.child(`systems/${this.$store.state.userData.system.id}/complaints/${this.complaintData.id}/${filename}`);
                        await imageRef.put(file);
                        const fileUrl = await imageRef.getDownloadURL();
                        this.newCase.files.images.push({url: fileUrl, name: filename, path: `systems/${this.$store.state.userData.system.id}/complaints/${this.complaintData.id}/${filename}`, type: file.type, size: file.size, originalName: file.name});
                    }

                    for(let y=0; y < pdfArr.length; y++)
                    {
                        const file = pdfArr[y].file;
                        const filename = "pdf_" + this.newCase.id.toString() + y.toString();
                        const pdfRef = storageRef.child(`systems/${this.$store.state.userData.system.id}/complaints/${this.complaintData.id}/${filename}`);
                        await pdfRef.put(file);
                        const fileUrl = await pdfRef.getDownloadURL();
                        this.newCase.files.pdfs.push({url: fileUrl, name: filename, path: `systems/${this.$store.state.userData.system.id}/complaints/${this.complaintData.id}/${filename}`, type: file.type, size: file.size, originalName: file.name});
                    }
                    this.newCase.meta.productNameProvidedByClient === undefined ? this.newCase.meta.productNameProvidedByClient = "" : this.newCase.meta.productNameProvidedByClient
                    await this.$store.state.userData.system.ref.collection("Complaints").doc(this.complaintData.id.toString()).update({
                        'cases': firebase.firestore.FieldValue.arrayUnion(this.newCase)
                    })
                    await this.updateAnalitycs()
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Case added with success!",
                        subheader: ``,
                        success: true
                    });
                    this.prepareNewCase();
                    this.closeModal();

                    this.$emit("refreshComplaint");
                    setTimeout(()=>
                    {
                      this.$store.commit('setGlobalLoader',{
                          show: false,
                          head: "Loading..",
                          subheader: `Do not close this tab.`,
                      });
                    },400)



                }
                catch(error)
                {
                    this.prepareNewCase();
                    this.closeModal();
                    this.$emit("refreshComplaint");
                    console.log(error);
                    this.$store.commit('setGlobalLoader',{
                        show: false,
                        head: "Loading..",
                        subheader: `Do not close this tab.`,
                    });
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Oops! Something went wrong!",
                        subheader: `Cannot add this case.`,
                        success: false
                    });
                }
                
                
            },
            setProduct(thing)
            {
                this.newCase.item.name = "";
                this.newCase.item.model = "";
                this.newCase.item.id = "";
                this.newCase.item.ean = "";
                this.newCase.item.ref = null;
                this.newCase.item.sku = "";
                this.newCase.item.imageAtAddingTime = "";
                this.newCase.item.sellPriceAtAddingTime = 0;
                this.newCase.item.productionCostAtAddingTime = 0;
                this.newCase.item.complaintCostAtAddingTime = 0;
                this.newCase.item.attributesAtAddingTime = [];
                // save
                this.newCase.item.name = thing.name;
                this.newCase.item.model = thing.model;
                this.newCase.item.id = thing.id;
                this.newCase.item.ean = thing.ean;
                this.newCase.item.ref = this.$store.state.userData.system.ref.collection("Products").doc(thing.id);
                this.newCase.item.sku = thing.sku;
                this.newCase.item.imageAtAddingTime = thing.image.url;
                this.newCase.item.sellPriceAtAddingTime = parseFloat(thing.pricesAndCosts.sellPrice);
                this.newCase.item.productionCostAtAddingTime = parseFloat(thing.pricesAndCosts.productionCost);
                this.newCase.item.complaintCostAtAddingTime = parseFloat(thing.pricesAndCosts.complaintCost);
                this.newCase.item.attributesAtAddingTime = thing.attribs.list;

                for(let y=0; y < this.newCase.item.attributesAtAddingTime.length; y++)
                {
                    this.newCase.item.attributesAtAddingTime[y].ref = this.$store.state.userData.system.ref.collection("Attributes").doc(this.newCase.item.attributesAtAddingTime[y].id);
                    this.newCase.item.attributesAtAddingTime[y].parent.ref = this.$store.state.userData.system.ref.collection("GroupsOfAttributes").doc(this.newCase.item.attributesAtAddingTime[y].parent.id);              
                }

                this.toggleSearchProducts();
            },
            toggleSearchProducts()
            {
              this.showSearchProduct = !this.showSearchProduct;
            },
            addToPDFHolder(event,holderId)
            {
              this.pdfHolder[holderId] = [];
              if(event.target.files.length === 0) return;
              const file = event.target.files[0];
              const sizeInMb = file.size / 1024 / 1024;
              if(sizeInMb <= this.perPDFSizeMB)
              {
                this.pdfHolder[holderId].push({file: file});
                this.$store.commit('setNotification',{
                  show: true,
                  head: "PDF file has been added!",
                  subheader: ``,
                  success: true
                }); 
              }
              else
              {
                  this.$store.commit('setNotification',{
                    show: true,
                    head: "Cannot add this PDF!",
                    subheader: `File size is more than ${this.perPDFSizeMB} MB.`,
                    success: false
                  });                
              }
            },
            triggerPDFUploaderClick(uploaderRefAsText)
            {
              this.$refs[uploaderRefAsText].click();
            },
            imgDelete(ref,flIndx)
            {
              ref.splice(flIndx,1);
              this.$store.commit('setNotification',{
                show: true,
                head: "Image has been deleted!",
                subheader: ``,
                success: true
              });
              this.$refs[`imgUpldr0`][flIndx].value = "";
              //this.imgHolder[].splice(flNdx,1)
            },
            triggerImgUploaderClick(uploaderRefAsText,left)
            {
              if(left === 0) return;
              this.$refs[uploaderRefAsText].click();
            },
            addToImgHolder(event,holderId)
            {
                
                if(event.target.files.length === 0) return;
                const file = event.target.files[0];
                const sizeInMb = file.size / 1024 / 1024;
                if(sizeInMb <= this.perImgSizeMB)
                {
                    let fileAlreadyAdded = false;

                    if(!fileAlreadyAdded)
                    {
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            const content = event.target.result;
                            this.imgHolder[holderId].push({file: file, dataURL: content});
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Image has been added!",
                                subheader: ``,
                                success: true
                            }); 
                        };
                        reader.readAsDataURL(file);
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Cannot add this image!",
                            subheader: `List contains file with same name.`,
                            success: false
                        });                
                    }

                
                
              }
              else
              {
                this.$store.commit('setNotification',{
                  show: true,
                  head: "Cannot add this image!",
                  subheader: `File size is more than ${this.perImgSizeMB} MB.`,
                  success: false
                });
              }

            },
            async getActions()
            {
              // Receive Actions
              const result = await db.collection("OptionValues").doc("ComplaintActions").get();
              if(result.exists)
              {
                this.actionsList = result.data().values;
              }
              const resultPrefByClient = await db.collection("OptionValues").doc("ExpectationsValues").get();
              if(resultPrefByClient.exists)
              {
                this.actionsPreferredByClientList = resultPrefByClient.data().values;
              }
            },
            async getReasons()
            {
              // Accepted
              const result = await db.collection("OptionValues").doc("ComplaintReasons").get();
              if(result.exists)
              {
                this.reasonsList = result.data().values;
              }
              // Denied
              const result2 = await db.collection("OptionValues").doc("ComplaintDenyReasons").get();
              if(result2.exists)
              {
                this.reasonsDenyList = result2.data().values;
              }
            },
            async getPriority()
            {
              // Get priority
              const result = await db.collection("OptionValues").doc("ComplaintPriority").get();
              if(result.exists)
              {
                this.priorityList = result.data().values;
                this.selectedPriority = result.data().values[0].name;
                this.newCase.priority = result.data().values[0].name;
              }
            },
            async getAttendants()
            {
              // Get attendants
              const result = await this.$store.state.userData.system.ref.get();
              if(result.exists)
              {
                this.attendants = result.data().users;
                this.selectedAttendant = result.data().users[0].id;
              }
            },
            correctDataWhenAcceptanceChanged(c)
            {
              if(c.acceptance === 'declined')
              {
                c.action = 'No action';
                c.reason = 'Other';
                c.completed = true;
              }
              if(c.acceptance === 'accepted')
              {
                c.reason = 'Other';
              }
            },
            prepareNewCase()
            {
              const caseTemplate = {
                completed: false,
                acceptance: 'accepted',
                id: generateApiKey({ method: 'string', pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", length: 128 }),
                item: {
                  type: "Product",
                  name: "",
                  ref: null,
                  model: "",
                  id: "",
                  ean: "",
                  sku: "",
                  attributesAtAddingTime: [],
                  currencyAtAddingTime: "USD",
                  imageAtAddingTime: "",
                  sellPriceAtAddingTime: 0,
                  productionCostAtAddingTime: 0,
                  complaintCostAtAddingTime: 0
                },
                files: {
                  images: [],
                  pdfs: [],
                  videos: [],
                  other: []
                },
                description: "",
                action: "No action",
                preferredActionByClient: "No information",
                reason: "Other",
                meta: {
                  productNameProvidedByClient: undefined,
                  filesOriginallyUploadedByClient: [],
                  haveError: false,
                  errorMsgs: [],
                  changes: {
                    last: {
                      userId: this.$store.state.user.uid,
                      date: moment().toDate(),
                      info: "Creation"
                    },
                    list: [{
                      userId: this.$store.state.user.uid,
                      date: moment().toDate(),
                      info: "Creation"                      
                    }]
                  },
                  createdBy: {
                    username: this.$store.state.user.displayName,
                    uid: this.$store.state.user.uid,
                    date: moment().toDate()
                  }
                }
              }
              this.imgHolder[`${caseTemplate.id}`] = [];
              this.pdfHolder[`${caseTemplate.id}`] = [];
              this.newCase = {...caseTemplate};

            },
            closeModal()
            {
                this.open = false;
                setTimeout(() => {
                    this.open = true;
                    this.$emit("close");
                },300)
            },
        },
        computed:
        {
        }
    }
</script>
<style lang="sthis.newCases" scoped>

</style>